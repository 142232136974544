import React, { useEffect, useState } from "react";
import { Card, Grid, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import burceMars from "assets/images/60111.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getSimpleLoginById, updateSimpleLogin } from "layouts/admin/components/Header/service";

function Header() {
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [name, setName] = useState("Guest");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [country, setCountry] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({});

  // Fetch user data using API
  useEffect(() => {
    const userDataFromSession = JSON.parse(sessionStorage.getItem("userData"));

    if (userDataFromSession && userDataFromSession.id) {
      setUserData(userDataFromSession);
      fetchData(userDataFromSession.id);
    } else {
      console.error("No valid user data found in sessionStorage");
    }
  }, []);

  const fetchData = async (id) => {
    try {
      const response = await getSimpleLoginById(id);
      setName(response.userName || "Guest");
      setUserName(response.userName || "");
      setAddress(response.address || "");
      setContact(response.contact || "");
      setCountry(response.country || "");
      setUserPassword(response.userPassword || "");
    } catch (error) {
      console.error("Error fetching user data from API", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedLogin = {
      id: userData.id,
      name: name,
      userName: userName,
      userPassword: userPassword,
      address: address,
      contact: contact,
      country: country,
    };

    try {
      const updatedUser = await updateSimpleLogin(updatedLogin);
      alert("Information updated successfully!");
    } catch (error) {
      console.error("Error updating user data:", error);
      alert("There was an error updating your information.");
    }
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card sx={{ position: "relative", mt: -8, mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                EDITOR
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {name}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Card
          sx={{
            mt: 4,
            p: 0,
            maxWidth: "1200px",
            mx: "auto",
            backgroundColor: "#f5f5f5",
            width: "100%",
            boxShadow: (theme) => theme.shadows[4],
          }}
        >
          <MDBox
            sx={{
              width: "100%",
              maxWidth: "500px",
              mx: "auto",
              mt: -2,
              backgroundColor: (theme) => theme.palette.info.main,
              borderRadius: "8px",
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: (theme) => theme.shadows[4],
            }}
          >
            <MDTypography variant="h6" color="white">
              Private Information
            </MDTypography>
          </MDBox>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="User Name"
                  variant="outlined"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Contact No"
                  variant="outlined"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Country"
                  variant="outlined"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                textAlign={"center"}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    color: "#fff", // Explicitly set text color to white
                  }}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Card>
    </MDBox>
  );
}

export default Header;
