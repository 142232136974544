import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Row, Col, CardBody, Container, Button, Alert } from "reactstrap";
import { MdCancel } from "react-icons/md";
import { createArticle } from "./articleApi";
import { fetchArticleById } from "layouts/allarticles/data/articleService";
import { updateArticle } from "./articleApi";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

function AddArticleForm({ articleData, roles }) {
  const [authorFields, setAuthorFields] = useState([{ id: 1 }]);
  const [authors, setAuthors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const articleId = searchParams.get("id");
  const [isViewMode, setIsViewMode] = useState(roles === "ADMIN" || roles === "EDITOR");
  const location = useLocation();
  const { file } = location.state || {};
  const [formData, setFormData] = useState({
    login: { id: null },
    articleComponent: "",
    title: "",
    abstractContent: "",
    keywords: "",
    file: "",
    msgToPublisher: "",
    authors: [
      {
        name: "",
        qualification: "",
        designation: "",
        institute: "",
        email: "",
        contact: "",
      },
    ],
    contributor: "",
    dateOfSubmission: "",
    expectedPublishDate: "",
  });

  const handleOpenFile = () => {
    const file = formData.file;
    if (!file) {
      console.error("No file data available");
      alert("No file attached or file data is missing.");
      return;
    }

    const base64Prefix = "data:application/pdf;base64,";
    const base64String = file.startsWith(base64Prefix) ? file : base64Prefix + file;

    try {
      const byteCharacters = atob(base64String.split(",")[1]);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], {
        type: "application/pdf",
      });

      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error processing file:", error);
      alert("There was an error with the file data.");
    }
  };

  useEffect(() => {}, [file]);

  useEffect(() => {
    if (articleData) {
      setFormData((prevData) => ({
        ...prevData,
        file: articleData.file || null,
      }));
    }
  }, [articleData]);

  const navigate = useNavigate();

  useEffect(() => {
    if (articleData) {
      setFormData(articleData);
    }
  }, [articleData]);

  useEffect(() => {
    const fetchArticleData = async () => {
      if (articleId) {
        try {
          const data = await fetchArticleById(articleId);
          setFormData(data);

          if (data.authors) {
            setAuthorFields(data.authors.map((author, index) => ({ id: index + 1 })));
          }
        } catch (error) {
          console.error("Error fetching article data:", error);
        }
      }
    };

    fetchArticleData();
  }, [articleId]);

  useEffect(() => {
    const loadAuthors = async () => {
      try {
        const fetchedAuthors = await fetchAuthors();
        setAuthors(fetchedAuthors);
      } catch (error) {
        console.error("Failed to fetch authors:", error);
      }
    };
    loadAuthors();
  }, []);

  useEffect(() => {
    const loginData = sessionStorage.getItem("user");
    if (loginData) {
      const user = JSON.parse(loginData);
      setFormData((prevState) => ({
        ...prevState,
        login: { id: user.id },
      }));
    } else {
    }
  }, []);

  const validateForm = () => {
    let errors = [];

    if (!formData.articleComponent) errors.push("Article Name is required.");
    if (!formData.title) errors.push("Title is required.");
    if (!formData.abstractContent) errors.push("abstractContent is required.");
    if (!formData.keywords) errors.push("Keywords are required.");

    formData.authors.forEach((author, index) => {
      if (!author.name) errors.push(`Author ${index + 1}: Name is required.`);
      if (!author.qualification) errors.push(`Author ${index + 1}: Qualification is required.`);
      if (!author.designation) errors.push(`Author ${index + 1}: Designation is required.`);
      if (!author.email) errors.push(`Author ${index + 1}: Email is required.`);
      if (!author.contact) errors.push(`Author ${index + 1}: Contact is required.`);
    });

    if (errors.length > 0) {
      setErrorMessage(errors.join(" "));
      return false;
    }
    return true;
  };

  const addAuthorFields = () => {
    if (authorFields.length < 4) {
      setAuthorFields([...authorFields, { id: authorFields.length + 1 }]);
      setFormData({
        ...formData,
        authors: [
          ...formData.authors,
          { name: "", qualification: "", designation: "", institute: "", email: "", contact: "" },
        ],
      });
    }
  };

  const handleCancel1 = (id) => {
    setAuthorFields(authorFields.filter((field) => field.id !== id));
    setFormData({
      ...formData,
      authors: formData.authors.filter((_, index) => index !== id - 1),
    });
  };

  const handleCancel = () => {
    navigate("/allarticles");
  };

  const handleInputChange = (e, fieldName) => {
    if (fieldName === "file") {
      setFormData({ ...formData, [fieldName]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [fieldName]: e.target.value });
    }
  };

  const handleAuthorInputChange = (index, fieldName, value) => {
    const updatedAuthors = [...formData.authors];
    updatedAuthors[index][fieldName] = value;
    setFormData({ ...formData, authors: updatedAuthors });
  };

  const renderFilePreview = () => {
    if (!formData.file) return null;
  };

  const fileInputRef = React.createRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage("");
    setErrorMessage("");

    if (!validateForm()) {
      setIsSubmitting(false);
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }

    const file = formData.file;

    if (!file) {
      setErrorMessage("Please select a file to upload.");
      setIsSubmitting(false);
      return;
    }

    try {
      if (articleId) {
        await updateArticle(articleId, formData, file);
        setSuccessMessage("Article updated successfully!");
      } else {
        await createArticle(formData, file);
        setSuccessMessage("Article created successfully!");
      }

      setIsSubmitting(false);
      setTimeout(() => setSuccessMessage(""), 3000);

      setFormData({
        articleComponent: "",
        title: "",
        abstractContent: "",
        keywords: "",
        file: null,
        msgToPublisher: "",
        authors: [
          { name: "", qualification: "", designation: "", institute: "", email: "", contact: "" },
        ],
        contributor: "",
        dateOfSubmission: "",
        expectedPublishDate: "",
      });
      setAuthorFields([{ id: 1 }]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error submitting article:", error);
      setErrorMessage("Failed to submit article. Please try again.");
      setIsSubmitting(false);
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f7f7f7",
        padding: "20px",
        borderRadius: "8px",
        margin: "10px",
      }}
    >
      <Container fluid>
        <Row>
          <Col xl={12}>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <h6 className="heading-small text-muted mb-4" style={{ fontSize: "0.875rem" }}></h6>
                <div className="form-header"></div>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-articleComponent"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Article Component <span className="text-danger">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-articleComponent"
                          placeholder="Enter Article Name"
                          type="text"
                          value={formData.articleComponent}
                          onChange={(e) => handleInputChange(e, "articleComponent")}
                          style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-title"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Title <span className="text-danger">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-title"
                          placeholder="Enter Title"
                          type="text"
                          value={formData.title}
                          onChange={(e) => handleInputChange(e, "title")}
                          style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label style={{ fontSize: "0.875rem" }}>
                        Abstract (in 250 words) <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Description"
                        rows="4"
                        type="textarea"
                        value={formData.abstractContent}
                        onChange={(e) => handleInputChange(e, "abstractContent")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                        required
                      />
                    </FormGroup>
                  </div>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-keywords"
                          style={{ fontSize: "0.875rem" }}
                        >
                          Keywords <span className="text-danger">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-keywords"
                          placeholder="Keywords"
                          type="text"
                          value={formData.keywords}
                          onChange={(e) => handleInputChange(e, "keywords")}
                          style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-file"
                          style={{ fontSize: "0.875rem" }}
                        >
                          File {isViewMode && "(View Only)"}
                        </label>

                        {isViewMode ? (
                          <>
                            {renderFilePreview()}

                            {(roles === "ADMIN" || roles === "EDITOR") && formData.file && (
                              <Button
                                variant="outlined"
                                onClick={handleOpenFile}
                                sx={{
                                  borderRadius: "20px",
                                  marginTop: "10px",
                                  marginBottom: "20px",
                                  textTransform: "none",
                                  color: "green",
                                  borderColor: "green",
                                  boxShadow: (theme) => theme.shadows[4],
                                  "&:hover": {
                                    borderColor: "green",
                                    backgroundColor: "rgba(0, 128, 0, 0.1)",
                                  },
                                }}
                              >
                                View Attached File
                              </Button>
                            )}
                          </>
                        ) : (
                          <Input
                            className="form-control-alternative"
                            id="file"
                            accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            name="fileToUpload"
                            placeholder="No File Chosen"
                            type="file"
                            onChange={(e) => handleInputChange(e, "file")}
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="pl-lg-4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-msgToPublisher"
                      style={{ fontSize: "0.875rem" }}
                    >
                      Message to Publisher <span className="text-danger">*</span>
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-msgToPublisher"
                      placeholder="Enter Message to Publisher"
                      rows="2"
                      type="textarea"
                      value={formData.msgToPublisher}
                      onChange={(e) => handleInputChange(e, "msgToPublisher")}
                      style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                    />
                  </FormGroup>
                </div>

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4" style={{ fontSize: "0.875rem" }}>
                  Author’s
                </h6>
                {authorFields.map((field, index) => (
                  <div key={field.id} className="pl-lg-4">
                    <h6 className="heading-small text-muted mb-4" style={{ fontSize: "0.875rem" }}>
                      {index === 0 ? "Author 1" : `Author ${index + 1}`}
                      {index !== 0 && (
                        <MdCancel
                          onClick={() => handleCancel1(field.id)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "1rem",
                            marginLeft: "10px",
                          }}
                        />
                      )}
                    </h6>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-authorname-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Name of Author <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-authorname-${field.id}`}
                            placeholder="Enter Name of Author"
                            type="text"
                            value={formData.authors[field.id - 1].name}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "name", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-qualification-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Qualification <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-qualification-${field.id}`}
                            placeholder="Enter Qualification"
                            type="text"
                            value={formData.authors[field.id - 1].qualification}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "qualification", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-designation-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Designation <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-designation-${field.id}`}
                            placeholder="Enter Designation"
                            type="text"
                            value={formData.authors[field.id - 1].designation}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "designation", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-authorname-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Name of Institute
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-authorname-${field.id}`}
                            placeholder="Enter Name of Institute"
                            type="text"
                            value={formData.authors[field.id - 1].institute}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "institute", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-email-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Email <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-email-${field.id}`}
                            placeholder="Enter Email"
                            type="email"
                            value={formData.authors[field.id - 1].email}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "email", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`input-contact-${field.id}`}
                            style={{ fontSize: "0.875rem" }}
                          >
                            Contact No <span className="text-danger">*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id={`input-contact-${field.id}`}
                            placeholder="Enter Contact No"
                            type="tel"
                            value={formData.authors[field.id - 1].contact}
                            onChange={(e) =>
                              handleAuthorInputChange(field.id - 1, "contact", e.target.value)
                            }
                            style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                ))}
                {roles !== "ADMIN" && roles !== "EDITOR" && (
                  <Button
                    onClick={addAuthorFields}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Add More Authors
                  </Button>
                )}

                <hr className="my-4" />
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-contributorname"
                        style={{ fontSize: "0.875rem" }}
                      >
                        Financial Contributor or Sponsor Agency{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-contributorname"
                        placeholder="Enter Contributor or Sponsor Agency"
                        type="text"
                        value={formData.contributor}
                        onChange={(e) => handleInputChange(e, "contributor")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-date"
                        style={{ fontSize: "0.875rem" }}
                      >
                        Date of Submission <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-date"
                        placeholder="Enter Date"
                        type="date"
                        format="DD/MM/YYYY"
                        value={formData.dateOfSubmission}
                        onChange={(e) => handleInputChange(e, "dateOfSubmission")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-month-year"
                        style={{ fontSize: "0.875rem" }}
                      >
                        Expected date for publishing the article{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-month-year"
                        placeholder="Enter date"
                        type="date"
                        format="DD/MM/YYYY"
                        value={formData.expectedPublishDate}
                        onChange={(e) => handleInputChange(e, "expectedPublishDate")}
                        style={{ fontSize: "0.875rem", padding: "0.5rem" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {roles === "USER" && (
                  <div className="d-flex justify-content-center mt-4">
                    <Button color="success" type="submit" disabled={isSubmitting} className="me-2">
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                    <Button color="danger" type="button" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                )}
              </Form>
              {successMessage && <Alert color="success">{successMessage}</Alert>}
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </CardBody>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

AddArticleForm.propTypes = {
  articleData: PropTypes.object,
  roles: PropTypes.oneOf(["ADMIN", "EDITOR", "USER"]).isRequired,
};

export default AddArticleForm;
