import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import MKTypography from "components/MKTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Information from "layouts/abouthome/sections/Information";
import Services from "layouts/abouthome/sections/Services";
import bgImage from "assets/images/team-young-specialist-doctors-standing-corridor-hospital.jpg";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import PhoneIcon from "@mui/icons-material/Phone";

function AboutHome() {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);

  const handleClick = (buttonName, route) => {
    setActiveButton(buttonName);
    navigate(route);
  };

  const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;

  const AnimatedTypography = styled(MKTypography)(({ theme }) => ({
    animation: `${fadeIn} 1s ease-in-out forwards`,
  }));

  return (
    <>
      <DefaultNavbar />
      <MKBox
        minHeight={{ xs: "1000px" }}
        width="100%"
        pt={{ xs: 2, sm: 2, md: 4, lg: 5 }}
        pb={{ xs: 0, sm: 0, md: 0, lg: 0 }}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", sm: "90%", md: "80%", lg: "75%" },
            padding: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              mx: "auto",
              textAlign: "center",
              padding: { xs: 2, sm: 3, md: 4 },
            }}
          >
            {/* <AnimatedTypography
              variant="h3"
              color="white"
              sx={({ breakpoints }) => ({
                fontWeight: "bold",
                letterSpacing: "0.5px",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.8)",
                fontSize: { xs: "5vw", sm: "3.5vw", md: "2.5vw", lg: "2vw" },
                lineHeight: { xs: 1.2, sm: 1.3, md: 1.5 },
                fontFamily: "'Roboto', sans-serif",
              })}
            >
              RAMVILAS FOUNDATION&apos;S
            </AnimatedTypography> */}

            <AnimatedTypography
              variant="h1"
              color="success"
              sx={({ breakpoints }) => ({
                fontWeight: "bold",
                letterSpacing: "0.5px",
                fontFamily: "'Playfair Display', serif",
                textShadow: "3px 3px 3px rgba(0, 0, 0, 0.8)",
                fontSize: { xs: "10vw", sm: "8vw", md: "6vw", lg: "5vw" },
                lineHeight: { xs: 1.1, sm: 1.2, md: 1.3 },
              })}
            >
              AYURDRISHTI
            </AnimatedTypography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            mb={-10}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MDTypography
              color="light"
              fontWeight="bold"
              textTransform="uppercase"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.25rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
                textAlign: "center",
              }}
            >
              JOURNAL OF DRUG RESEARCH, INNOVATION FOR SYSTEM OF HEALTH AND TRADITIONAL INDIAN
              MEDICINE.
            </MDTypography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ flexDirection: "row", mt: 4 }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <MDButton
                  variant="contained"
                  color="success"
                  size="large"
                  sx={{
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      backgroundColor: "#388E3C",
                    },
                    width: "100%",
                    minHeight: "65px",
                    borderRadius: "0px",
                  }}
                  onClick={() => handleClick("publish", "/authentication/sign-in")}
                >
                  <PublishIcon sx={{ mr: 1 }} />
                  Publish Article Here
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MDButton
                  variant="contained"
                  color="success"
                  size="large"
                  sx={{
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      backgroundColor: "#388E3C",
                    },
                    width: "100%",
                    minHeight: "65px",
                    borderRadius: "0px",
                  }}
                  onClick={() => handleClick("find", "/articles")}
                >
                  <SearchIcon sx={{ mr: 1 }} />
                  Find Article Paper
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MDButton
                  variant="contained"
                  color="success"
                  size="large"
                  sx={{
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      backgroundColor: "#388E3C",
                    },
                    width: "100%",
                    minHeight: "65px",
                    borderRadius: "0px",
                  }}
                  onClick={() => handleClick("guidelines", "/authorguideline")}
                >
                  <DescriptionIcon sx={{ mr: 1 }} />
                  Author Guidelines
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MDButton
                  variant="contained"
                  color="success"
                  size="large"
                  sx={{
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      backgroundColor: "#388E3C",
                    },
                    width: "100%",
                    minHeight: "65px",
                    borderRadius: "0px",
                  }}
                  onClick={() => handleClick("contact", "/contact")}
                >
                  <PhoneIcon sx={{ mr: 1 }} />
                  Contact Us
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -20,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Services />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter />
      </MKBox>
    </>
  );
}

export default AboutHome;
