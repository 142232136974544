import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { fetchEditors } from "layouts/userlist/data/fetchUsers";
import EditorForm from "./data/EditorForm";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUser } from "./data/fetchUsers";

function UserList() {
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const fetchUsersData = async () => {
    try {
      const users = await fetchEditors();
      setUsers(users);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const handleStatusClick = async (user) => {
    const newStatus = user.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    try {
      await axios.put(`https://server.ayurdrishti.com/users/${user.id}/status`, {
        status: newStatus,
      });
      fetchUsersData();
    } catch (error) {
      console.error("Error updating user status", error);
    }
  };

  const handleAdd = () => {
    setEditingUser(null);
    setShowForm(true);
  };

  const handleEdit = (id) => {
    const userToEdit = users.find((user) => user.id === id);
    setEditingUser(userToEdit);
    setShowForm(true);
  };

  const handleUserCreatedOrUpdated = () => {
    fetchUsersData();
    setShowForm(false);
  };

  const handleDelete = async (loginId) => {
    if (!loginId) {
      console.error("No loginId provided for deletion.");
      return;
    }

    try {
      await deleteUser(loginId);
      fetchUsersData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleDeleteClick = async (userId) => {
    try {
      await deleteUser(userId);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user", error);
    }
  };

  const columns = [
    { Header: "Name", accessor: "name", isSorted: true, isSortedDesc: false },
    { Header: "Email", accessor: "email", isSorted: true, isSortedDesc: false },
    { Header: "Contact", accessor: "contact", isSorted: true, isSortedDesc: false },
    { Header: "Address", accessor: "address", isSorted: true, isSortedDesc: false },
    { Header: "Country", accessor: "country", isSorted: true, isSortedDesc: false },
    { Header: "Role", accessor: "role", isSorted: true, isSortedDesc: false },
    {
      Header: "Status",
      accessor: "status",
      isSorted: true,
      isSortedDesc: false,
      Cell: ({ row }) => (
        <Button
          variant="contained"
          onClick={() => handleStatusClick(row.original)}
          sx={{
            backgroundColor: row.original.status === "ACTIVE" ? "green" : "red",
            color: "#fff",
            "&:hover": {
              backgroundColor: row.original.status === "ACTIVE" ? "darkgreen" : "darkred",
            },
          }}
        >
          {row.original.status}
        </Button>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      isSorted: false,
    },
  ];

  const rows = users.map((user) => ({
    id: user.loginId,
    name: user.name,
    email: user.email,
    contact: user.contact,
    address: user.address,
    country: user.country,
    role: user.roles,
    status: user.status,
    action: (
      <IconButton
        onClick={() => handleDelete(user.loginId)}
        sx={{
          color: "red",
          "&:hover": {
            color: "darkred",
          },
        }}
      >
        <DeleteIcon />
      </IconButton>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white" align="center">
                  Peer Team Overview
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="right" sx={{ padding: 2 }}>
                <Button
                  onClick={handleAdd}
                  variant="outlined"
                  color="info"
                  sx={{
                    marginRight: 1,
                    borderColor: "info.main",
                    color: "info.main",
                    "&:hover": {
                      backgroundColor: "info.light",
                      borderColor: "info.dark",
                    },
                  }}
                  startIcon={<FaPlus />}
                >
                  Add Peer Team
                </Button>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {showForm && (
          <EditorForm
            user={editingUser}
            onClose={() => setShowForm(false)}
            onUserCreatedOrUpdated={handleUserCreatedOrUpdated}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

UserList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      isSorted: PropTypes.bool,
      isSortedDesc: PropTypes.bool,
      Cell: PropTypes.func,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  }),
};

export default UserList;
