import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AdminDashboard from "layouts/admindashboard/components/AdminDashboard";
import { fetchAllLogin } from "layouts/admin/components/Header/service";
import { fetchArticleDetails } from "layouts/allarticles/data/articleService";

function Overview() {
  const [editorCount, setEditorCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [articleCount, setArticleCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [publishedArticleCount, setPublishedArticleCount] = useState(0);

  const fetchArticleCount = async () => {
    setLoading(true);
    try {
      const response = await fetchArticleDetails();
      const articles = response || [];

      setArticleCount(articles.length);
      const publishedArticles = articles.filter((article) => article.published === "YES");
      setPublishedArticleCount(publishedArticles.length);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticleCount();
  }, []);

  const fetchEditorCount = async () => {
    setLoading(true);
    try {
      const response = await fetchAllLogin();

      if (Array.isArray(response)) {
        const count = response.filter((user) => user.roles === "EDITOR").length;
        setEditorCount(count);
      } else {
        console.error("Invalid data format in response:", response);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEditorCount();
  }, []);

  const fetchUserCount = async () => {
    setLoading(true);
    try {
      const response = await fetchAllLogin();

      if (Array.isArray(response)) {
        const count = response.filter((user) => user.roles === "USER").length;
        setUserCount(count);
      } else {
        console.error("Invalid data format in response:", response);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserCount();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AdminDashboard
          editorCount={editorCount}
          userCount={userCount}
          articleCount={articleCount}
          publishedArticleCount={publishedArticleCount}
        />
      </MDBox>
      {loading && <MDBox>Loading...</MDBox>}
    </DashboardLayout>
  );
}

export default Overview;
