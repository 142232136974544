import axios from "axios";

const token = sessionStorage.getItem("csrfToken");

const axiosInstance = axios.create({
  baseURL: "https://server.ayurdrishti.com/api/article-details",
  headers: {
    Authorisation: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized access - token might be expired or invalid.");
    }
    return Promise.reject(error);
  }
);

const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get("/users");
    return response;
  } catch (error) {
    console.error("Error fetching users", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axios.post("https://server.ayurdrishti.com/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

const updateUser = async (id, user) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, user);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (loginId) => {
  try {
    await axiosInstance.delete(`/users/${loginId}`);
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export { fetchUsers, updateUser };

const fetchEditors = async () => {
  try {
    const response = await axiosInstance.get("/editors", {
      params: { roles: "Editor" },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching editors", error);
    throw error;
  }
};

export { fetchEditors };
