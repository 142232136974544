import axios from "axios";

const api = axios.create({
  baseURL: "https://server.ayurdrishti.com/rest",
});
export const createArticle = async (articleData, file) => {
  try {
    const formData = new FormData();

    const loginData = sessionStorage.getItem("user");
    if (loginData) {
      const user = JSON.parse(loginData);
      const { file: _, ...articleWithoutFile } = articleData;

      formData.append("article", JSON.stringify({ ...articleWithoutFile, login: { id: user.id } }));
    } else {
      throw new Error("No login data found in sessionStorage.");
    }

    if (file) {
      const validFileTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (!validFileTypes.includes(file.type)) {
        throw new Error("Only Word files (.doc, .docx) are allowed.");
      }

      const maxFileSize = 10 * 1024 * 1024;
      if (file.size > maxFileSize) {
        throw new Error("File size should not exceed 10 MB.");
      }

      formData.append("file", file);
    }

    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("No authentication token found.");
    }

    const response = await api.post("/articles", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorisation: `Token ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error submitting article:", error);
    if (error.response) {
      throw new Error(error.response?.data || "An error occurred");
    } else {
      throw new Error(error.message || "An error occurred while uploading the article");
    }
  }
};

export const updateArticle = async (id, updatedData, file) => {
  try {
    const formData = new FormData();

    const loginData = sessionStorage.getItem("user");
    if (loginData) {
      const user = JSON.parse(loginData);
      const { file: _, ...articleWithoutFile } = updatedData;
      formData.append("article", JSON.stringify({ ...articleWithoutFile, login: { id: user.id } }));
    } else {
      throw new Error("No login data found in sessionStorage.");
    }

    if (file && !file.type) {
      // Check for Word file, and assign correct MIME type
      file = new File([atob(file)], "uploaded.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
    }

    if (file) {
      // Allow Word files (.docx) and PDFs
      const validFileTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (!validFileTypes.includes(file.type)) {
        throw new Error("Only PDF and Word (DOCX) files are allowed.");
      }

      const maxFileSize = 10 * 1024 * 1024; // 10 MB max size
      if (file.size > maxFileSize) {
        throw new Error("File size should not exceed 10 MB.");
      }

      formData.append("file", file);
    }

    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("No authentication token found.");
    }

    const response = await api.put(`/articles/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorisation: `Token ${token}`, // Fix typo here: 'Authorization' instead of 'Authorisation'
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating article details:", error);
    if (error.response) {
      throw new Error(error.response?.data || "An error occurred while updating the article");
    } else {
      throw new Error(error.message || "An error occurred while updating the article");
    }
  }
};

export const fetchArticle = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getallarticle`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};
