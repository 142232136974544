import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UserDashboard from "layouts/userdashboard/components/UserDashboard";
import { fetchArticleDetails } from "layouts/allarticles/data/articleService"; // Fetch articles

function Overview() {
  const [articleCount, setArticleCount] = useState(0);
  const [publishedArticleCount, setPublishedArticleCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchArticleCount = async () => {
    setLoading(true);
    try {
      const response = await fetchArticleDetails();
      const articles = response || []; // Ensure response data is not null or undefined

      setArticleCount(articles.length); // Count all articles
      const publishedArticles = articles.filter((article) => article.published === "YES");
      setPublishedArticleCount(publishedArticles.length); // Count published articles
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticleCount(); // Fetch article count when the component mounts
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {loading ? (
          <MDBox>Loading...</MDBox> // Show loading text while fetching data
        ) : (
          <UserDashboard
            articleCount={articleCount}
            publishedArticleCount={publishedArticleCount}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
