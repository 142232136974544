import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { fetchUsers } from "layouts/userlist/data/fetchUsers";
import { deleteUser } from "./data/fetchUsers";
import UserForm from "./data/userForm";
import { useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";

const ViewFileButton = ({ row }) => (
  <Button
    variant="outlined"
    onClick={() => handleOpenFile(row.original.file)}
    style={{
      borderRadius: "20px",
      margin: "0 5px",
      textTransform: "none",
      color: "green",
      borderColor: "green",
    }}
  >
    View File
  </Button>
);

const handleOpenFile = (fileData) => {
  try {
    if (!fileData) {
      console.error("File not found");
      return;
    }

    const base64Prefix = "data:application/pdf;base64,";
    const base64String = fileData.startsWith(base64Prefix) ? fileData : base64Prefix + fileData;

    const byteCharacters = atob(base64String.split(",")[1]);
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    window.open(url, "_blank");
  } catch (error) {
    console.error("Error handling file:", error);
  }
};

function UserList() {
  const [users, setUsers] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [articles, setArticles] = useState(null);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [articleHistory, setArticleHistory] = useState(null);
  const [isHistoryViewOpen, setIsHistoryViewOpen] = useState(false);

  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);

  const [articlePage, setArticlePage] = useState(0);
  const [articleRowsPerPage, setArticleRowsPerPage] = useState(10);

  const [historyPage, setHistoryPage] = useState(0);
  const [historyRowsPerPage, setHistoryRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const fetchUsersData = async () => {
    try {
      const users = await fetchUsers();
      setUsers(users);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  const fetchArticleHistory = async (articleId) => {
    try {
      const response = await axios.get(
        `https://server.ayurdrishti.com/api/article-details/articles/${articleId}/history`
      );
      setArticleHistory(response.data);
      setIsHistoryViewOpen(true);
    } catch (error) {
      console.error("Error fetching article history for articleId:", articleId, error);
    }
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const handleDelete = async (id) => {
    if (!id) {
      console.error("No loginId provided for deletion. Received value:", id); // Debugging
      return;
    }

    try {
      await deleteUser(id);
      fetchUsersData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleStatusClick = async (user) => {
    const newStatus = user.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    try {
      await axios.put(`https://server.ayurdrishti.com/users/${user.id}/status`, {
        status: newStatus,
      });
      fetchUsersData();
    } catch (error) {
      console.error("Error updating user status", error);
    }
  };

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Contact", accessor: "contact" },
    { Header: "Address", accessor: "address" },
    { Header: "Country", accessor: "country" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Button
          variant="contained"
          onClick={() => handleStatusClick(row.original)}
          sx={{
            backgroundColor: row.original.status === "ACTIVE" ? "green" : "red",
            color: "#fff",
            "&:hover": {
              backgroundColor: row.original.status === "ACTIVE" ? "darkgreen" : "darkred",
            },
            padding: "6px 12px",
            borderRadius: "4px",
            textTransform: "none",
          }}
        >
          {row.original.status}
        </Button>
      ),
    },
    {
      Header: "All Submission",
      accessor: "role",
      Cell: ({ row }) => (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "green",
            color: "#fff",
            "&:hover": { backgroundColor: "darkgreen" },
            padding: "6px 12px",
            borderRadius: "4px",
            textTransform: "none",
          }}
          onClick={async () => {
            const userId = row.original.id;
            try {
              const response = await axios.get(
                `https://server.ayurdrishti.com/api/article-details/articles/login/${userId}`
              );
              setArticles(response.data);
              setIsViewOpen(true);
            } catch (error) {
              console.error("Error fetching articles for userId:", userId, error);
            }
          }}
        >
          View
        </Button>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        return (
          <div>
            <IconButton onClick={() => handleEditClick(row.original)}>
              <EditIcon />
            </IconButton>
            {/* <IconButton onClick={() => handleDelete(row.original?.id)}>
              <DeleteIcon />
            </IconButton> */}
          </div>
        );
      },
    },
  ];

  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };

  const handleChangeUserRowsPerPage = (event) => {
    setUserRowsPerPage(parseInt(event.target.value, 10));
    setUserPage(0);
  };

  const handleChangeArticlePage = (event, newPage) => {
    setArticlePage(newPage);
  };

  const handleChangeArticleRowsPerPage = (event) => {
    setArticleRowsPerPage(parseInt(event.target.value, 10));
    setArticlePage(0);
  };

  const handleChangeHistoryPage = (event, newPage) => {
    setHistoryPage(newPage);
  };

  const handleChangeHistoryRowsPerPage = (event) => {
    setHistoryRowsPerPage(parseInt(event.target.value, 10));
    setHistoryPage(0);
  };

  const rows = users.map((user) => ({
    key: user.loginId, // Add key
    id: user.loginId,
    name: user.name,
    email: user.email,
    contact: user.contact,
    address: user.address,
    country: user.country,
    role: user.roles,
    status: user.status,
    action: user,
  }));

  const articleColumns = [
    { Header: "Sr. No", accessor: "srNo" },
    { Header: "Title", accessor: "title" },
    {
      Header: "View File",
      accessor: "file",
      align: "center",
      Cell: ViewFileButton,
    },
    { Header: "Article Component", accessor: "articleComponent" },
    { Header: "Abstract", accessor: "abstractContent" },
    {
      Header: "Author Name",
      accessor: "authorsName",
      Cell: ({ row }) => {
        const authors = row.original.authors;
        return authors.map((author, index) => <div key={index}>{author.name}</div>);
      },
    },
    {
      Header: "Qualification",
      accessor: "authorsQualification",
      Cell: ({ row }) => {
        const authors = row.original.authors;
        return authors.map((author, index) => <div key={index}>{author.qualification}</div>);
      },
    },
    {
      Header: "Designation",
      accessor: "authorsDesignation",
      Cell: ({ row }) => {
        const authors = row.original.authors;
        return authors.map((author, index) => <div key={index}>{author.designation}</div>);
      },
    },
    {
      Header: "Institute",
      accessor: "authorsInstitute",
      Cell: ({ row }) => {
        const authors = row.original.authors;
        return authors.map((author, index) => <div key={index}>{author.institute}</div>);
      },
    },
    {
      Header: "Email",
      accessor: "authorsEmail",
      Cell: ({ row }) => {
        const authors = row.original.authors;
        return authors.map((author, index) => <div key={index}>{author.email}</div>);
      },
    },
    {
      Header: "Contact",
      accessor: "authorsContact",
      Cell: ({ row }) => {
        const authors = row.original.authors;
        return authors.map((author, index) => <div key={index}>{author.contact}</div>);
      },
    },
    { Header: "Keywords", accessor: "keywords" },
    { Header: "Publisher", accessor: "msgToPublisher" },
    { Header: "Contributor", accessor: "contributor" },
    { Header: "Date", accessor: "dateOfSubmission" },
    { Header: "Action", accessor: "action" },
  ];

  const articleRows = articles?.map((article, index) => ({
    srNo: index + 1,
    title: article.title,
    file: article.file,
    articleComponent: article.articleComponent,
    abstractContent: article.abstractContent,
    authors: article.authors,
    keywords: article.keywords,
    msgToPublisher: article.msgToPublisher,
    contributor: article.contributor,
    dateOfSubmission: article.dateOfSubmission,
    action: (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "green",
          color: "#fff",
          "&:hover": { backgroundColor: "darkgreen" },
        }}
        onClick={() => fetchArticleHistory(article.id)}
      >
        View History
      </Button>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white" align="center">
                  Users Overview
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: rows.slice(
                      userPage * userRowsPerPage,
                      userPage * userRowsPerPage + userRowsPerPage
                    ),
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={userRowsPerPage}
                  page={userPage}
                  onPageChange={handleChangeUserPage}
                  onRowsPerPageChange={handleChangeUserRowsPerPage}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {isViewOpen && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h4" color="white" align="center">
                    Articles Overview
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{
                      columns: articleColumns,
                      rows: articleRows.slice(
                        articlePage * articleRowsPerPage,
                        articlePage * articleRowsPerPage + articleRowsPerPage
                      ),
                    }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                  component="div"
                  count={articleRows.length}
                  rowsPerPage={articleRowsPerPage}
                  page={articlePage}
                  onPageChange={handleChangeArticlePage}
                  onRowsPerPageChange={handleChangeArticleRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}

      {isEditModalOpen && (
        <UserForm
          user={selectedUser}
          onClose={() => setIsEditModalOpen(false)}
          onUserCreatedOrUpdated={fetchUsersData}
        />
      )}

      {isHistoryViewOpen && articleHistory && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h4" color="white" align="center">
                    Article History
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{
                      columns: [
                        { Header: "Sr.No", accessor: "id" },
                        { Header: "Title", accessor: "title" },
                        { Header: "File", accessor: "file", Cell: ViewFileButton, align: "center" },
                        { Header: "Article Component", accessor: "articleComponent" },
                        { Header: "Abstract", accessor: "abstractContent" },
                        { Header: "Keywords", accessor: "keywords" },
                        { Header: "Submission Date", accessor: "dateOfSubmission" },
                        { Header: "Expected Publish Date", accessor: "expectedPublishDate" },
                        { Header: "Created Date", accessor: "createdDate" },
                        { Header: "Updated Date", accessor: "updatedDate" },
                        { Header: "History Date", accessor: "historyDate" },
                      ],
                      rows: articleHistory
                        .slice(
                          historyPage * historyRowsPerPage,
                          historyPage * historyRowsPerPage + historyRowsPerPage
                        )
                        .map((item, index) => ({
                          id: index + 1,
                          title: item.title,
                          file: item.file,
                          articleComponent: item.articleComponent,
                          abstractContent: item.abstractContent,
                          keywords: item.keywords,
                          dateOfSubmission: item.dateOfSubmission,
                          expectedPublishDate: item.expectedPublishDate,
                          createdDate: item.createdDate,
                          updatedDate: item.updatedDate,
                          historyDate: item.historyDate,
                        })),
                    }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                  component="div"
                  count={articleHistory.length}
                  rowsPerPage={historyRowsPerPage}
                  page={historyPage}
                  onPageChange={handleChangeHistoryPage}
                  onRowsPerPageChange={handleChangeHistoryRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default UserList;

ViewFileButton.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      file: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

UserList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      isSorted: PropTypes.bool,
      isSortedDesc: PropTypes.bool,
      Cell: PropTypes.func,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      action: PropTypes.object,
    })
  ),
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      loginId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          qualification: PropTypes.string.isRequired,
          designation: PropTypes.string.isRequired,
          institute: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          contact: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
};
