import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MDTypography from "components/MDTypography";
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

import img3 from "assets/images/img3.jpeg";
import img4 from "assets/images/img4.jpeg";
import img8 from "assets/images/img8.jpeg";
import img5 from "assets/images/img5.jpeg";
import img6 from "assets/images/img6.jpeg";
import img7 from "assets/images/img7.jpeg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-0.5}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MDTypography
              fontWeight="bold"
              sx={{
                fontSize: {
                  sm: "2rem",
                },
                textAlign: "left",
                color: "#007BFF",
              }}
            >
              Meet Editorial Members
            </MDTypography>
            <MDTypography
              fontWeight="medium"
              sx={{
                fontSize: {
                  xs: "0.875rem",
                },
                textAlign: "left",
                color: "#D3D3D3",
                opacity: 0.8,
              }}
            >
              Our editorial team is dedicated to upholding the highest standards of quality and
              integrity in every article. With diverse expertise, they meticulously review
              submissions to ensure a rigorous publication process.
            </MDTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={img5}
                name="Dr. Milind Babarao Aware"
                position={{ color: "info", label: "Editor in Chief" }}
                description={
                  <>
                    <div>
                      <strong>Qualification:</strong> MD Samhita Siddhant
                    </div>
                    <div>
                      <strong>Address:</strong> Maitri Avenue B, Mahalaxmi Nagar, Hirawadi Road
                      Panchvati, Nashik, Maharashtra, India 422003
                    </div>
                    <div>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:mbaware@ayurdrishti.com">milindaware@ayurdrishti.com</a>
                    </div>
                    <div>
                      <strong>Personal Email:</strong>{" "}
                      <a href="mailto:drmilindaware@gmail.com">drmilindaware@gmail.com</a>
                    </div>
                    <div>
                      <strong>Contact:</strong> +91 96657 66600
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={img3}
                name="Dr. Kiran Pandurang Shinde"
                position={{ color: "info", label: "Editor" }}
                description={
                  <>
                    <div>
                      <strong>Qualification:</strong> MD Kayachikitsa, MA Sanskrit
                    </div>
                    <div>
                      <strong>Address:</strong> 745 M H B Colony Satpur Nashik, Maharashtra, India,
                      422007
                    </div>
                    <div>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:kpshinde@ayurdrishti.com">kiranshinde@ayurdrishti.com</a>
                    </div>
                    <div>
                      <strong>Personal Email:</strong>{" "}
                      <a href="mailto:vd.kirans11@gmail.com">vd.kirans11@gmail.com</a>
                    </div>
                    <div>
                      <strong>Contact:</strong> +91 8087370701
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={img6}
                name="Dr. Vijay Raghunath Potkule"
                position={{ color: "info", label: "Managing Editor" }}
                description={
                  <>
                    <div>
                      <strong>Qualification:</strong> MD Kriya Sharir
                    </div>
                    <div>
                      <strong>Address:</strong> Row House No 1, Uma Avenue, Avadhut Colony,
                      Rasbihari meri link road, Panchavati, Nashik, Maharashtra, India 422003
                    </div>
                    <div>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:vrpotkule@ayurdrishti.com">vijaypotkule@ayurdrishti.com</a>
                    </div>
                    <div>
                      <strong>Personal Email:</strong>{" "}
                      <a href="mailto:drvijayrao85@gmail.com">drvijayrao85@gmail.com</a>
                    </div>
                    <div>
                      <strong>Contact:</strong> +91 8806768981
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={img8}
                name="Dr. Pritam Suryabhan Pawale"
                position={{ color: "info", label: "Managing Editor" }}
                description={
                  <>
                    <div>
                      <strong>Qualification:</strong> MD Dravyaguna Vigyan
                    </div>
                    <div>
                      <strong>Address:</strong> Plot no 20, Varad Professor Colony, Panchavati,
                      Nashik, 422003
                    </div>
                    <div>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:pspawale@ayurdrishti.com">pritampawale@ayurdrishti.com</a>
                    </div>
                    <div>
                      <strong>Personal Email:</strong>{" "}
                      <a href="mailto:pritam4ayurveda@gmail.com">pritam4ayurveda@gmail.com</a>
                    </div>
                    <div>
                      <strong>Contact:</strong> +91 84129784341
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={img7}
                name="Dr. Amit Shyam Diwakar"
                position={{ color: "info", label: "Patron" }}
                description={
                  <>
                    <div>
                      <strong>Qualification:</strong> MD Kayachikitsa
                    </div>
                    <div>
                      <strong>Address:</strong> Cornell University, Ithaca, New York
                    </div>
                    <div>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:asdiwakar@ayurdrishti.com">amitdiwakar@ayurdrishti.com</a>
                    </div>
                    <div>
                      <strong>Personal Email:</strong>{" "}
                      <a href="mailto:dramitdiwakar69@rediffmail.com">
                        dramitdiwakar69@rediffmail.com
                      </a>
                    </div>
                    <div>
                      <strong>Contact:</strong> +1 607-339-6617
                    </div>
                  </>
                }
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
