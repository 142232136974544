import axios from "axios";

const BASE_URL = "https://server.ayurdrishti.com/api/article-details";

export const fetchArticlesData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/simple`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles data:", error);
    throw error;
  }
};

export const fetchAllArticlesData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getallsimple`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles data:", error);
    throw error;
  }
};

export const fetchVerifiedArticles = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getVerifiedArticles`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles data:", error);
    throw error;
  }
};
