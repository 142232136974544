import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const AssignVerifierDataTable = ({ onEdit }) => {
  const [assignVerifiers, setAssignVerifiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAssignVerifiers = async () => {
    try {
      const response = await axios.get(
        "https://server.ayurdrishti.com/api/assign-verifiers/getAllAssignVerifiers"
      );

      const formattedData = response.data.map((verifier, index) => {
        const editorName =
          verifier.login?.roles === "EDITOR" ? verifier.login.name : "Not Assigned";

        return {
          srNo: index + 1,
          articleTitle: verifier.article?.title || "Untitled",
          status: verifier.status || "NEED_CORRECTION",
          comments: verifier.comment || "No Comment",
          editorName: editorName,
          action: (
            <div>
              <IconButton color="secondary" onClick={() => handleDelete(verifier.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        };
      });

      setAssignVerifiers(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error.message, error.response || error);
      setError("Error fetching data. Please check the console for details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignVerifiers();
  }, [onEdit]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this verifier?")) {
      try {
        await axios.delete(
          `https://server.ayurdrishti.com/api/assign-verifiers/deleteAssignVerifier/${id}`
        );

        fetchAssignVerifiers(); // Call fetchAssignVerifiers here
      } catch (error) {
        console.error("Error deleting Assign Verifier:", error);
      }
    }
  };

  const VerifiedButton = ({ row }) => {
    const status = row.original.status;

    const buttonStyles = {
      borderRadius: "20px",
      margin: "0 5px",
      textTransform: "none",
      borderColor: status === "VERIFIED" || status === "ASSIGNED" ? "green" : "red",
      color: status === "VERIFIED" || status === "ASSIGNED" ? "green" : "red",
    };

    const buttonText =
      status === "VERIFIED" ? "Verified" : status === "ASSIGNED" ? "Assigned" : "Need Correction";

    return (
      <Button variant="outlined" style={buttonStyles}>
        {buttonText}
      </Button>
    );
  };

  VerifiedButton.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        status: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <DataTable
      table={{
        columns: [
          { Header: "Sr. No", accessor: "srNo" },
          { Header: "Article Name", accessor: "articleTitle" },
          { Header: "Status", accessor: "status", align: "center", Cell: VerifiedButton },
          { Header: "Comments", accessor: "comments" },
          { Header: "Editor", accessor: "editorName" }, // New column for editor's name
          { Header: "Action", accessor: "action" },
        ],
        rows: assignVerifiers,
      }}
      isSorted={false}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
};

AssignVerifierDataTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default AssignVerifierDataTable;
