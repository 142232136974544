import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Breadcrumbs from "examples/Breadcrumbs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const navigate = useNavigate();
  const route = useLocation().pathname.split("/").slice(1);
  const role = sessionStorage.getItem("userRole");

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const userId = sessionStorage.getItem("userId");
      if (!userId) {
        console.error("No userId found in sessionStorage");
        return;
      }

      const apiUrl = `https://server.ayurdrishti.com/api/user/${userId}`;

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setNotifications(data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenNotifications = (event) => {
    setOpenNotifications(event.currentTarget);
  };
  const handleCloseNotifications = () => {
    setOpenNotifications(false);
  };

  const handleProfileClick = () => {
    if (role === "USER") {
      navigate("/profile");
    } else if (role === "ADMIN") {
      navigate("/admin");
    }
    handleCloseMenu();
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/authentication/sign-in");
    handleCloseMenu();
  };

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setOpenDialog(true);
  };

  const handleShowAllNotifications = () => {
    setShowAllNotifications(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedNotification(null);
    setShowAllNotifications(false);
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(timestamp)) / 1000);

    if (diffInSeconds < 60) {
      return "Just Now";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MenuItem onClick={handleProfileClick}>
        <Icon>account_circle</Icon> Profile
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <Icon>logout</Icon> Log out
      </MenuItem>
    </Menu>
  );

  const renderNotifications = () => (
    <Menu
      anchorEl={openNotifications}
      open={Boolean(openNotifications)}
      onClose={handleCloseNotifications}
      sx={{ mt: 2 }}
    >
      <MDBox sx={{ width: "300px" }}>
        <Typography variant="h6" sx={{ padding: 1 }}>
          Notifications
        </Typography>
        <Divider />
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification)}>
              <Typography variant="body2">{notification.title}</Typography>
              <Typography variant="body2" sx={{ fontSize: "12px", color: "gray" }}>
                {/* {getTimeAgo(notification.timestamp)} */}
              </Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No new notifications</MenuItem>
        )}

        <Divider />
        <MenuItem onClick={handleShowAllNotifications}>
          <Typography color="primary" sx={{ textAlign: "center" }}>
            Show All Notifications
          </Typography>
        </MenuItem>
      </MDBox>
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton onClick={handleOpenNotifications} size="small" disableRipple>
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={handleOpenMenu}>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton size="small" disableRipple color="inherit" onClick={handleFullScreen}>
                <Icon className="feather feather-maximize" sx={iconsStyle} />
              </IconButton>
              {renderMenu()}
              {renderNotifications()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{
          "& .MuiDialog-paper": {
            width: "800px",
          },
        }}
      >
        <DialogTitle>
          {showAllNotifications ? "All Notifications" : selectedNotification?.title}
        </DialogTitle>
        <DialogContent>
          {showAllNotifications ? (
            <MDBox>
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <Typography variant="body2" key={notification.id}>
                    <strong>{notification.title}</strong>
                    <br />
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "gray" }}>
                      {notification.description}
                    </Typography>
                    <Divider sx={{ marginTop: "10px" }} />
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">No notifications available.</Typography>
              )}
            </MDBox>
          ) : (
            <Typography variant="body1">{selectedNotification?.description}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleCloseDialog}
            style={{
              borderRadius: "10px",
              paddingRight: "10px",
              paddingLeft: "10px",
              backgroundColor: "#717478",
              color: "white",
              fontSize: "17px",
              fontFamily: "Arial",
            }}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
