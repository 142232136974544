import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  Typography,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import the PDF icon
import axios from "axios";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ArticleCard from "./ArticleCard";
import MDBox from "components/MDBox";
import bgImage from "assets/images/bg-about-us.jpeg";
import { fetchVerifiedArticles } from "./articleDataService";

const Articles = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false); // State to control popup visibility
  const [popupMessage, setPopupMessage] = useState(""); // Store message for popup
  const articlesPerPage = 10; // Set to display 10 articles per page

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchVerifiedArticles();
        setArticles(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredArticles = articles.filter((article) => {
    const matchesSearch = article.title?.toLowerCase().includes(searchTerm.toLowerCase());

    if (!selectedMonth) return matchesSearch;

    const articleDate = new Date(article.dateOfSubmission);
    const articleMonth = articleDate.getMonth();
    const selectedMonthIndex = selectedMonth - 1;

    return matchesSearch && articleMonth === selectedMonthIndex;
  });

  const handleDownloadOrViewFile = async (pdfFile, articleId) => {
    if (!pdfFile || pdfFile.trim() === "") {
      setPopupMessage("This article has not been verified yet. No file available.");
      setOpenPopup(true); // Open the popup
      return; // Early exit if no file
    }

    try {
      const response = await axios.get(
        `https://server.ayurdrishti.com/api/article-details/download/${articleId}`,
        { responseType: "blob" }
      );

      // Check if the response is actually a valid PDF file
      const contentType = response.headers["content-type"];
      if (!contentType || !contentType.includes("pdf")) {
        throw new Error("Invalid file format received. Expected PDF.");
      }

      const blob = response.data;
      const fileURL = window.URL.createObjectURL(blob);

      // Check if the file is being opened in a new tab or downloaded
      const link = document.createElement("a");
      link.href = fileURL;
      const fileName = `article_${articleId}.pdf`;

      // For automatic opening of the PDF in a new tab
      window.open(fileURL, "_blank"); // This will open the file in a new tab

      // If you want to download as well, uncomment this:
      // link.setAttribute("download", fileName);
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error); // This will not be part of the popup
      setPopupMessage("Failed to download the PDF file.");
      setOpenPopup(true); // Open the popup if there's an error
    }
  };

  const handleOpenFile = (fileData) => {
    try {
      if (!fileData || fileData.trim() === "") {
        setPopupMessage("This article has not been verified yet. No file available.");
        setOpenPopup(true); // Open the popup
        return;
      }

      const base64Prefix = "data:application/pdf;base64,";
      const base64String = fileData.startsWith(base64Prefix) ? fileData : base64Prefix + fileData;

      const byteCharacters = atob(base64String.split(",")[1]);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (error) {
      console.error("Error handling file:", error); // This will not be part of the popup
    }
  };

  const getArticlesByAcceptedYear = () => {
    const groupedArticles = {};

    articles.forEach((article) => {
      const acceptedDate = new Date(article.expectedPublishDate);
      const year = acceptedDate.getFullYear();

      if (!groupedArticles[year]) {
        groupedArticles[year] = [];
      }

      groupedArticles[year].push(article);
    });

    return groupedArticles;
  };

  const groupedArticlesByYear = getArticlesByAcceptedYear();
  const currentYearArticles = groupedArticlesByYear[new Date().getFullYear()] || [];

  const volume = currentYearArticles.length;

  const startIndex = (currentPage - 1) * articlesPerPage;
  const endIndex = startIndex + articlesPerPage;
  const paginatedArticles = filteredArticles.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography variant="h5">Loading articles...</Typography>
      </Box>
    );
  }

  // Modal to show popup messages
  const handleClosePopup = () => {
    setOpenPopup(false); // Close the popup when user clicks close
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MDBox position="relative">
        <MKBox
          minHeight={{ xs: "400px", sm: "500px" }}
          width="100%"
          pt={{ xs: 8, sm: 10, md: 12 }}
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Container
            sx={{
              position: "absolute",
              top: "25%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "white",
            }}
          >
            <MKTypography variant="h1" color="white">
              Articles
            </MKTypography>
            <MKTypography variant="h4" color="white" opacity={0.9} mt={1}>
              Stay Informed, Stay Engaged
            </MKTypography>
          </Container>
        </MKBox>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "saturate(200%) blur(30px)",
          }}
        >
          <MKBox mb={3} mt={5} display="flex" flexDirection="column" alignItems="center">
            <MKTypography variant="h3" mb={1}>
              Articles
            </MKTypography>
            <TextField
              label="Search Articles"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ maxWidth: { xs: "100%", sm: "500px", lg: "1000px" } }}
            />
          </MKBox>

          <MKTypography variant="h4" mb={1}>
            Current Issue
          </MKTypography>

          <MKTypography variant="h5" mb={1}>
            Vol. 1 Issue No. 1 ({new Date().getFullYear()}): January - March 2025
          </MKTypography>

          <MKTypography variant="h6" mb={1}>
            Review Articles
          </MKTypography>

          <FormControl sx={{ mb: 3, maxWidth: "200px", height: "60px" }}>
            <InputLabel>Filter by Month</InputLabel>
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              label="Filter by Month"
              sx={{
                height: "30px",
                padding: "20px",
              }}
            >
              <MenuItem value="">All Months</MenuItem>
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </Select>
          </FormControl>

          <Grid container direction="column" spacing={2}>
            {paginatedArticles.length > 0 ? (
              paginatedArticles.map((article, index) => {
                return (
                  <Grid item key={index}>
                    <ArticleCard
                      title={article.title}
                      journal="Title"
                      pdfFile={article.pdfFile}
                      articleId={article.articleId}
                      articleDetails={article}
                      handleDownloadFile={handleOpenFile}
                      handleOpenFile={handleOpenFile}
                    />
                  </Grid>
                );
              })
            ) : (
              <Typography variant="h6" textAlign="center">
                No articles found.
              </Typography>
            )}
          </Grid>

          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={Math.ceil(filteredArticles.length / articlesPerPage)} // Calculate total pages
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Card>
      </MDBox>

      {/* Custom Popup/Modal */}
      <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>Article Information</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{popupMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter />
      </MKBox>
    </>
  );
};

export default Articles;
