import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import MDTypography from "components/MDTypography";
import { fetchArticleDetails, updateArticleDetails } from "./articleService";
import Visibility from "@mui/icons-material/Visibility";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { fetchArticleDetailsByLoginId } from "./articleService";
import axios from "axios";
import { updateVerifiedStatus } from "./articleService";
import { updatePublishedStatus } from "./articleService";

export default function DataTable() {
  const [rows, setRows] = useState([]);
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const role = sessionStorage.getItem("userRole");
    setUserRole(role);
  }, []);

  useEffect(() => {
    const role = sessionStorage.getItem("userRole");
    setUserRole(role);
  }, []);

  useEffect(() => {
    if (!userRole) return;

    const fetchArticles = async () => {
      try {
        let articles;
        if (userRole === "USER") {
          articles = await fetchArticleDetailsByLoginId();
        } else {
          articles = await fetchArticleDetails();
        }

        const formattedArticles = articles.map((article, index) => ({
          id: article.articleId,
          srNo: index + 1,
          title: article.title,
          file: article.file,
          verified: article.verified,
          published: article.published,
          publishedDate:
            article.published === "YES"
              ? article.publishedDate || new Date().toLocaleDateString()
              : "N/A",

          upload: userRole === "ADMIN" ? <UploadPDFButton articleId={article.articleId} /> : null,
          action:
            userRole === "ADMIN" || userRole === "EDITOR" ? (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="info"
                fontWeight="large"
                onClick={() => handleEdit(article.articleId, article.file)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  cursor: "pointer",
                  fontSize: "14px",
                  "&:hover": {
                    transform: "scale(2.2)",
                    color: "primary.main",
                  },
                }}
              >
                <Visibility sx={{ fontSize: "inherit" }} />
              </MDTypography>
            ) : userRole === "USER" && article.published === "NO" ? (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="info"
                fontWeight="medium"
                onClick={() => handleEdit(article.articleId, article.file)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  cursor: "pointer",
                  fontSize: "14px",
                }}
              >
                <FaEdit />
              </MDTypography>
            ) : userRole === "USER" && article.published === "YES" ? (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="info"
                fontWeight="medium"
                onClick={() => handleView(article.articleId)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  cursor: "pointer",
                }}
              >
                <FaEdit />
              </MDTypography>
            ) : (
              ""
            ),
        }));

        setRows(formattedArticles);
      } catch (error) {
        console.error("Error fetching articles: ", error);
      }
    };

    fetchArticles();
  }, [userRole]);

  const handleEdit = async (id, file) => {
    navigate(`/addarticlesform?id=${id}`, { state: { file } });
  };

  const handleView = async (id) => {
    navigate(`/addarticlesform?id=${id}`);
  };

  const handleToggleVerified = async (index) => {
    if (userRole !== "ADMIN") {
      return;
    }

    try {
      const updatedArticle = await updateVerifiedStatus(rows[index].id);

      const formattedArticle = {
        ...rows[index],
        verified: updatedArticle.verified,
      };

      setRows((prevRows) => prevRows.map((row, i) => (i === index ? formattedArticle : row)));
    } catch (error) {
      console.error("Error updating verified status: ", error);
    }
  };

  const handleTogglePublished = async (index) => {
    try {
      const updatedArticle = await updatePublishedStatus(rows[index].id);

      const formattedArticle = {
        ...rows[index],
        published: updatedArticle.published,
        publishedDate: updatedArticle.published === "YES" ? updatedArticle.publishedDate : "N/A",
      };

      setRows((prevRows) => prevRows.map((row, i) => (i === index ? formattedArticle : row)));
    } catch (error) {
      console.error("Error updating published status: ", error);
    }
  };

  const handleOpenFile = (fileData, fileType) => {
    try {
      if (!fileData) {
        console.error("File not found");
        return;
      }

      // Ensure the base64 string is in the right format
      const base64Prefix = "data:" + fileType + ";base64,";

      // Remove the prefix if it exists, or keep the string as is
      const base64String = fileData.startsWith(base64Prefix) ? fileData.split(",")[1] : fileData;

      // Decode base64 string
      const byteCharacters = atob(base64String);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: fileType });

      // Create a link element to simulate the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "downloaded_file" + (fileType === "application/msword" ? ".doc" : ".docx"); // Add file extension
      link.click(); // Simulate the click to download the file
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  const ViewFileButton = ({ row }) => (
    <Button
      variant="outlined"
      onClick={() => handleOpenFile(row.original.file, row.original.fileType)}
      style={{
        borderRadius: "20px",
        margin: "0 5px",
        textTransform: "none",
        color: "green",
        borderColor: "green",
      }}
    >
      Download File
    </Button>
  );

  const VerifiedButton = ({ row }) => {
    const { verified } = row.original;
    const isVerified = verified === "VERIFIED";

    const isDisabled = isVerified || userRole === "USER";

    return (
      <Button
        variant="outlined"
        onClick={() => !isDisabled && handleToggleVerified(row.index)}
        style={{
          borderColor: isVerified ? "green" : "red",
          color: isVerified ? "green" : "red",
          borderRadius: "20px",
          margin: "0 5px",
          textTransform: "none",
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        disabled={isDisabled}
      >
        {isVerified ? "Verified" : "Not Verified"}
      </Button>
    );
  };

  const PublishedButton = ({ row }) => (
    <Button
      variant="outlined"
      onClick={() => userRole !== "USER" && handleTogglePublished(row.index)}
      style={{
        borderColor: row.original.published === "YES" ? "green" : "red",
        color: row.original.published === "YES" ? "green" : "red",
        borderRadius: "20px",
        margin: "0 5px",
        textTransform: "none",
        cursor: userRole === "USER" ? "not-allowed" : "pointer",
      }}
      disabled={userRole === "USER"}
    >
      {row.original.published === "YES" ? "Yes" : "No"}{" "}
    </Button>
  );

  const PublishedDate = ({ row }) => {
    const { published, publishedDate } = row.original;

    const formattedDate =
      published === "YES" && publishedDate ? new Date(publishedDate).toLocaleDateString() : "N/A";

    return (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {formattedDate}
      </MDTypography>
    );
  };

  const UploadPDFButton = ({ articleId }) => {
    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.type === "application/pdf") {
          try {
            await uploadPDF(file, articleId);
            alert("PDF uploaded successfully!");
          } catch (error) {
            alert("Error uploading PDF: " + error.message);
            console.error("Upload error:", error);
          }
        } else {
          alert("Please upload a valid PDF file.");
        }
      }
    };

    return (
      <Button
        variant="outlined"
        component="label"
        style={{
          borderRadius: "20px",
          margin: "0 5px",
          textTransform: "none",
          color: "blue",
          borderColor: "blue",
        }}
      >
        Upload PDF
        <input type="file" accept="application/pdf" hidden onChange={handleFileChange} />
      </Button>
    );
  };

  UploadPDFButton.propTypes = {
    articleId: PropTypes.number.isRequired,
  };

  const uploadPDF = async (file, articleId) => {
    const formData = new FormData();
    formData.append("pdfFile", file);
    formData.append("articleId", articleId);

    try {
      const response = await axios.post(
        "https://server.ayurdrishti.com/api/article-details/upload-pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error uploading PDF:", error);
      throw new Error("Error uploading PDF: " + error.message);
    }
  };

  ViewFileButton.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        file: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  VerifiedButton.propTypes = {
    row: PropTypes.shape({
      index: PropTypes.number.isRequired,
      original: PropTypes.shape({
        verified: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  PublishedButton.propTypes = {
    row: PropTypes.shape({
      index: PropTypes.number.isRequired,
      original: PropTypes.shape({
        published: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  PublishedDate.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        published: PropTypes.string.isRequired,
        publishedDate: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  return {
    columns: [
      {
        Header: "Sr. No",
        accessor: "srNo",
        width: "10%",
        align: "center",
      },
      {
        Header: "Title",
        accessor: "title",
        align: "left",
      },
      {
        Header: "Publication Copy",
        accessor: "file",
        align: "center",
        Cell: ViewFileButton,
      },
      ...(userRole === "ADMIN"
        ? [
            {
              Header: "Upload",
              accessor: "upload",
              align: "center",
            },
          ]
        : []),
      {
        Header: "Status",
        accessor: "verified",
        align: "center",
        Cell: VerifiedButton,
      },

      {
        Header: "Published",
        accessor: "published",
        align: "center",
        Cell: PublishedButton,
      },
      {
        Header: "Published Date",
        accessor: "publishedDate",
        align: "center",
        Cell: PublishedDate,
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
      },
    ],
    rows,
  };
}
