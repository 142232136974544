import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchAllArticlesData } from "layouts/articles/articleDataService";
import { fetchEditors } from "layouts/editorslist/data/fetchUsers";
import axios from "axios";

const AssignVerifierForm = ({ onAssignSuccess }) => {
  const [selectedSubmission, setSelectedSubmission] = useState("");
  const [selectedEditor, setSelectedEditor] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("ASSIGNED");
  const [articles, setArticles] = useState([]);
  const [editors, setEditors] = useState([]);
  const [createDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetchAllArticlesData();
        setArticles(response && Array.isArray(response) ? response : []);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setArticles([]);
      }
    };

    const fetchEditorsList = async () => {
      try {
        const response = await fetchEditors();
        setEditors(response && Array.isArray(response) ? response : []);
      } catch (error) {
        console.error("Error fetching editors:", error);
        setEditors([]);
      }
    };

    fetchArticles();
    fetchEditorsList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedSubmission || !selectedEditor) {
      alert("Please fill all the fields.");
      return;
    }

    const selectedArticle = articles.find((article) => article.title === selectedSubmission);
    const selectedEditorObj = editors.find((editor) => editor.name === selectedEditor);

    if (!selectedArticle || !selectedEditorObj) {
      alert("Invalid selection.");
      return;
    }

    const assignVerifierPayload = {
      article: { id: selectedArticle.articleId },
      login: { id: selectedEditorObj.loginId },
      createDate: createDate,
      status: status, // Include the status explicitly
    };

    try {
      const response = await axios.post(
        "https://server.ayurdrishti.com/api/assign-verifiers/createAssignVerifier",
        assignVerifierPayload
      );

      onAssignSuccess(response.data);
      alert("Verifier successfully assigned!");
    } catch (error) {
      console.error("Error creating assignVerifier:", error);
      alert("Failed to assign verifier. Please try again.");
    }
  };

  return (
    <Card
      sx={{
        mt: 4,
        p: 0,
        maxWidth: "1200px",
        mx: "auto",
        width: "100%",
        boxShadow: (theme) => theme.shadows[4],
      }}
    >
      <MDBox
        sx={{
          width: "100%",
          maxWidth: "500px",
          mx: "auto",
          mt: -2,
          backgroundColor: (theme) => theme.palette.info.main,
          borderRadius: "8px",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: (theme) => theme.shadows[4],
        }}
      >
        <MDTypography variant="h6" color="white">
          Assign Verifier
        </MDTypography>
      </MDBox>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Select Article"
              variant="outlined"
              select
              value={selectedSubmission || ""}
              onChange={(e) => setSelectedSubmission(e.target.value)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                IconComponent: () => <span style={{ marginLeft: "8px" }}>▼</span>,
              }}
              InputProps={{
                style: { height: "40px", padding: "8px 14px" },
              }}
            >
              {articles.length > 0 ? (
                articles.map((article) => (
                  <MenuItem key={article.articleId} value={article.title}>
                    {article.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No articles available</MenuItem>
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Select Peer Team Member"
              variant="outlined"
              select
              value={selectedEditor || ""}
              onChange={(e) => setSelectedEditor(e.target.value)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                IconComponent: () => <span style={{ marginLeft: "8px" }}>▼</span>,
              }}
              InputProps={{
                style: { height: "40px", padding: "8px 14px" },
              }}
            >
              {editors.length > 0 ? (
                editors.map((editor) => (
                  <MenuItem key={editor.loginId} value={editor.name}>
                    {editor.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No editors available</MenuItem>
              )}
            </TextField>
          </Grid>

          {/* Comment Field */}
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label="Comment"
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                style: { height: "40px", padding: "8px 14px" },
              }}
            />
          </Grid> */}

          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label="Select Status"
              variant="outlined"
              select
              value={status || ""}
              onChange={(e) => setStatus(e.target.value)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                IconComponent: () => <span style={{ marginLeft: "8px" }}>▼</span>,
              }}
              InputProps={{
                style: { height: "40px", padding: "8px 14px" },
              }}
            >
              <MenuItem value="ASSIGNED">ASSIGNED</MenuItem>
              <MenuItem value="VERIFIED">VERIFIED</MenuItem>
              <MenuItem value="NEED_CORRECTION">NEED_CORRECTION</MenuItem>
            </TextField>
          </Grid> */}

          {/* Submit Button */}
          <Grid item xs={12} textAlign="center">
            <Button
              type="submit"
              variant="outlined"
              color="info"
              sx={{
                marginRight: 1,
                borderColor: "info.main",
                color: "info.main",
                "&:hover": { backgroundColor: "info.light", borderColor: "info.dark" },
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

AssignVerifierForm.propTypes = {
  onAssignSuccess: PropTypes.func.isRequired,
};

export default AssignVerifierForm;
