import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import Card from "@mui/material/Card";

import DefaultAboutInfoCard from "examples/Cards/DefaultAboutInfoCard";
import Typography from "@mui/material/Typography";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <MKBox mb={5}>
          <MKBox
            sx={{
              backgroundColor: "#E3F2FD", // Light blue background
              borderRadius: "8px",
              boxShadow: 3,
              padding: 2,
            }}
          >
            <DefaultAboutInfoCard
              icon="flag"
              title="Mission Statement"
              color="primary"
              description="Ramvilas Foundation aims to empower communities through quality medical education, social welfare initiatives, and Ayurveda development, fostering a holistic approach to healthcare and well-being."
            />
          </MKBox>
        </MKBox>

        <MKBox
          sx={{
            backgroundColor: "#E3F2FD",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: 2,
            margin: "0 auto",
            textAlign: "center", // Center the content
            mb: 5,
          }}
        >
          {/* Custom Card for Publisher and Editor Details */}
          <Typography variant="h4" fontWeight="bold" sx={{ textDecoration: "underline" }}>
            Publisher Details
          </Typography>

          {/* <Typography variant="body3">Ramvilas Foundation</Typography> */}
          <Typography variant="body1">Office Address</Typography>
          <Typography variant="body1">
            Maitri Avenue B, Mahalaxmi Nagar, Hirawadi Road, Panchvati, Nashik, Maharashtra, India
            422003
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong>{" "}
            <span style={{ textDecoration: "underline" }}>
              ayurdrishti@gmail.com, ayurdrishti@ayurdrishti.com{" "}
            </span>
          </Typography>

          <Typography variant="body1" sx={{ mb: 3 }}>
            {" "}
          </Typography>

          <Typography variant="h4" fontWeight="bold" sx={{ textDecoration: "underline" }}>
            Chief Editor
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            Dr. Milind Babarao Aware
          </Typography>
          <Typography variant="h6">Editor in Chief</Typography>
          <Typography variant="body1">
            <strong>Address:</strong> Maitri Avenue B, Mahalaxmi Nagar, Hirawadi Road, Panchvati,
            Nashik,
          </Typography>
          <Typography variant="body3">Maharashtra, India 422003</Typography>

          <Typography variant="body1">
            <strong>Email:</strong>{" "}
            <span style={{ textDecoration: "underline" }}>milindaware@ayurdrishti.com</span>
          </Typography>
          <Typography variant="body1">
            <strong>Personal Email:</strong> drmilindaware@gmail.com
          </Typography>
          <Typography variant="body1">
            <strong>Contact:</strong> +91 96657 66600
          </Typography>
        </MKBox>

        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} lg={6}>
            <MKBox mb={5}>
              <MKBox
                sx={{
                  backgroundColor: "#C8E6C9", // Light green background
                  borderRadius: "8px",
                  boxShadow: 3,
                  padding: 2,
                }}
              >
                <DefaultAboutInfoCard
                  icon="healing"
                  title="Objectives"
                  color="success"
                  description="Promote medical education and research. Conduct social welfare programs for marginalized communities. Develop and expand Ayurveda practices. Foster collaboration between conventional and traditional medicine. Indexing AyurDrishti journal to UGC care list."
                />
              </MKBox>
            </MKBox>
            <MKBox mb={5}>
              <MKBox
                sx={{
                  backgroundColor: "#BBDEFB",
                  borderRadius: "8px",
                  boxShadow: 3,
                  padding: 2,
                }}
              >
                <DefaultAboutInfoCard
                  icon="assignment"
                  title="Initiatives"
                  color="info"
                  description="AyurDrishti Research Journal: A peer-reviewed publication for sharing research findings in Ayurveda and allied sciences. Medical Education Programs: Workshops, conferences, and training sessions for healthcare professionals. Social Welfare Projects: Health camps, community outreach, and support for underprivileged groups. Ayurveda Development: Research, cultivation, and promotion of medicinal plants."
                />
              </MKBox>
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox mb={5}>
              <MKBox
                sx={{
                  backgroundColor: "#FFF9C4", // Light yellow background
                  borderRadius: "8px",
                  boxShadow: 3,
                  padding: 2,
                }}
              >
                <DefaultAboutInfoCard
                  icon="group"
                  title="Governance"
                  color="warning"
                  description="Board of Trustees: Comprising experts in medicine, Ayurveda, and social work. Advisory Committee: Renowned professionals guiding strategic decisions. Executive Team: Responsible for day-to-day operations."
                />
              </MKBox>
            </MKBox>
            <MKBox mb={5}>
              <MKBox
                sx={{
                  backgroundColor: "#FFCCBC", // Light orange background
                  borderRadius: "8px",
                  boxShadow: 3,
                  padding: 2,
                }}
              >
                <DefaultAboutInfoCard
                  icon="handshake"
                  title="Collaborations"
                  color="error"
                  description="Ramvilas Foundation welcomes partnerships with healthcare institutions, research organizations, social welfare groups, and Ayurveda industry stakeholders."
                />
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
