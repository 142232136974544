import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AddArticleForm from "./AddArticleForm";

function Header() {
  const roles = sessionStorage.getItem("userRole");
  const articleId = new URLSearchParams(location.search).get("id");

  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          mt: 4,
          p: 0,
          maxWidth: "1200px",
          mx: "auto",
          backgroundColor: "#f5f5f5",
          width: "100%",
          boxShadow: (theme) => theme.shadows[4],
        }}
      >
        <MDBox
          sx={{
            width: "100%",
            maxWidth: "500px",
            mx: "auto",
            mt: -2,
            backgroundColor: (theme) => theme.palette.info.main,
            borderRadius: "8px",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: (theme) => theme.shadows[4],
          }}
        >
          <MDTypography variant="h6" color="white">
            {roles === "USER"
              ? articleId
                ? "Update Article Details"
                : "Add Article Details"
              : "Article Details"}{" "}
          </MDTypography>
        </MDBox>
        <AddArticleForm roles={roles} articleId={articleId} />
      </Card>
    </MDBox>
  );
}

export default Header;
