import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import axios from "axios";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Snackbar } from "@mui/material";

function Cover() {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    address: "",
    country: "",
    email: "",
    userPassword: "",
    repeatPassword: "",
    c1: false,
    c2: false,
    c3: false,
    userName: "",
  });

  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const navigate = useNavigate();

  const handleTogglePassword = () => setShowPassword(!showPassword);
  const handleToggleRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (userPassword) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(userPassword);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, contact, address, country, email, userPassword, repeatPassword, userName } =
      formData;
    let formErrors = {};

    if (!name) formErrors.name = "Name is required.";
    if (!contact) formErrors.contact = "Contact is required.";
    else if (contact.length !== 10 || isNaN(contact)) {
      formErrors.contact = "Contact must be exactly 10 digits.";
    }

    if (!address) formErrors.address = "Address is required.";
    if (!country) formErrors.country = "Country is required.";
    if (!email) formErrors.email = "Email is required.";
    else if (!validateEmail(email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (!userPassword) formErrors.userPassword = "Password is required.";
    else if (!validatePassword(userPassword)) {
      formErrors.userPassword =
        "Password must be at least 8 characters, include uppercase, lowercase, a number, and a special character.";
    }

    if (!repeatPassword) formErrors.repeatPassword = "Please confirm your userPassword.";
    else if (userPassword !== repeatPassword) {
      formErrors.repeatPassword = "Passwords do not match.";
    }

    if (!userName) formErrors.userName = "Username is required.";

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const requestBody = {
      ...formData,
      userName,
      token: "some-token-here",
      status: "ACTIVE",
      createdDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      active: true,
      c1: formData.c1 ? "Y" : "N",
      c2: formData.c2 ? "Y" : "N",
      c3: formData.c3 ? "Y" : "N",
    };

    try {
      await axios.post("https://server.ayurdrishti.com/register", requestBody);
      setSnackbarMessage("Sign-up successful!");
      setOpenSnackbar(true);

      setFormData({
        name: "",
        contact: "",
        address: "",
        country: "",
        email: "",
        userPassword: "",
        repeatPassword: "",
        c1: false,
        c2: false,
        c3: false,
        userName: "",
      });

      setTimeout(() => {
        navigate("/authentication/sign-in");
      }, 1000);
    } catch (error) {
      setSnackbarMessage("Error submitting form. Please try again.");
      setOpenSnackbar(true);
      console.error("Error submitting form:", error);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
        px={2}
        sx={{ flexDirection: "column" }}
      >
        <Card sx={{ width: { xs: "90%", sm: "70%", md: "600px" }, mb: 4 }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Join us today
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your information to get started with your account
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <MDBox display="flex" flexWrap="wrap" mb={3} sx={{ gap: 1 }}>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <MDTypography variant="caption" color="error">
                      {errors.name}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Contact"
                    name="contact"
                    variant="standard"
                    fullWidth
                    value={formData.contact}
                    onChange={handleChange}
                  />
                  {errors.contact && (
                    <MDTypography variant="caption" color="error">
                      {errors.contact}
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox display="flex" flexWrap="wrap" mb={3} sx={{ gap: 1 }}>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Address"
                    name="address"
                    variant="standard"
                    fullWidth
                    value={formData.address}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <MDTypography variant="caption" color="error">
                      {errors.address}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox sx={{ flex: { xs: "100%", md: "48%" } }}>
                  <MDInput
                    type="text"
                    label="Country"
                    name="country"
                    variant="standard"
                    fullWidth
                    value={formData.country}
                    onChange={handleChange}
                  />
                  {errors.country && (
                    <MDTypography variant="caption" color="error">
                      {errors.country}
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox mb={3}>
                <MDInput
                  type="email"
                  label="Email"
                  name="email"
                  variant="standard"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <MDTypography variant="caption" color="error">
                    {errors.email}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={3}>
                <MDInput
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  name="userPassword"
                  variant="standard"
                  fullWidth
                  value={formData.userPassword}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.userPassword && (
                  <MDTypography variant="caption" color="error">
                    {errors.userPassword}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={3}>
                <MDInput
                  type={showRepeatPassword ? "text" : "password"}
                  label="Repeat Password"
                  name="repeatPassword"
                  variant="standard"
                  fullWidth
                  value={formData.repeatPassword}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleToggleRepeatPassword} edge="end">
                          {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.repeatPassword && (
                  <MDTypography variant="caption" color="error">
                    {errors.repeatPassword}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={3}>
                <MDInput
                  type="text"
                  label="Username"
                  name="userName"
                  variant="standard"
                  fullWidth
                  value={formData.userName}
                  onChange={handleChange}
                />
                {errors.userName && (
                  <MDTypography variant="caption" color="error">
                    {errors.userName}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Sign Up
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <Snackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          autoHideDuration={3000}
        />
      </MDBox>
    </CoverLayout>
  );
}

export default Cover;
