import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { fetchContacts, deleteContact } from "layouts/contactdata/data/fetchContacts";

function ContactList() {
  const [contacts, setContacts] = useState([]);

  const fetchContactsData = async () => {
    try {
      const contactsData = await fetchContacts();
      setContacts(contactsData || []);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    fetchContactsData();
  }, []);

  const handleDeleteClick = async (contactId) => {
    try {
      await deleteContact(contactId);
      setContacts((prevContacts) => prevContacts.filter((contact) => contact.id !== contactId));
    } catch (error) {
      console.error("Error deleting contact", error);
    }
  };

  const rows = (contacts || []).map((contact, index) => ({
    srNo: index + 1,
    name: contact.name,
    email: contact.email,
    contact: contact.contact,
    message: contact.message,
    action: (
      <IconButton
        onClick={() => handleDeleteClick(contact.id)}
        sx={{
          color: "red",
          "&:hover": {
            color: "darkred",
          },
        }}
      >
        <DeleteIcon />
      </IconButton>
    ),
  }));

  const columns = [
    { Header: "SR No.", accessor: "srNo", isSorted: false },
    { Header: "Name", accessor: "name", isSorted: true, isSortedDesc: false },
    { Header: "Email", accessor: "email", isSorted: true, isSortedDesc: false },
    { Header: "Contact", accessor: "contact", isSorted: true, isSortedDesc: false },
    { Header: "Message", accessor: "message", isSorted: true, isSortedDesc: false },
    {
      Header: "Action",
      accessor: "action",
      isSorted: false,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white" align="center">
                  Contact Directory
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows,
                  }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

ContactList.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  }),
};

export default ContactList;
