import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import articlesTableData from "layouts/assignedarticle/data/articlesTableData";
import AssignArticleDataTable from "./data/articlesTableData";

function AssignTables() {
  const [assignArticle, setAssignVerifiers] = useState([]);

  const handleEdit = (verifier) => {};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{
                  width: "100%",
                  // maxWidth: "500px",
                  mx: "auto",
                  mt: -2,
                  backgroundColor: (theme) => theme.palette.info.main,
                  borderRadius: "8px",
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: (theme) => theme.shadows[4],
                }}
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Articles Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <AssignArticleDataTable assignArticle={assignArticle} onEdit={handleEdit} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AssignTables;
