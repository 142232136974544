import axios from "axios";

const BASE_URL = "https://server.ayurdrishti.com/api";
const BASE_URL1 = "https://server.ayurdrishti.com/api/article-details";

export const fetchArticleDetails = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/article-details`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};

export const fetchArticleDetailsByLoginId = async () => {
  try {
    const loginId = sessionStorage.getItem("userId");

    if (!loginId) {
      throw new Error("Login ID not found in session storage");
    }

    const response = await axios.get(`${BASE_URL1}/getarticlesbyloginid/${loginId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles by login ID:", error);
    throw error;
  }
};

export const updateArticleDetails = async (id, updatedData) => {
  try {
    const response = await axios.put(`${BASE_URL1}/verify/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error("Error updating article details:", error);
    throw error;
  }
};

export const saveArticleDetails = async (saveData) => {
  try {
    const response = await axios.post(`${BASE_URL1}/savepaymentamount`, saveData);
    return response.data;
  } catch (error) {
    console.error("Error saving payment amount:", error);
    throw error;
  }
};

export const downloadArticleFile = async (fileName) => {
  try {
    const response = await axios.get(`${BASE_URL}/file/${fileName}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

export const fetchArticleById = async (articleId) => {
  try {
    if (!articleId) {
      throw new Error("ID is required to fetch the article");
    }

    const response = await axios.get(`${BASE_URL1}/articles/${articleId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching article:", error);
    throw error;
  }
};

// ✅ Only updates the Verified status
export const updateVerifiedStatus = async (id) => {
  try {
    const response = await axios.put(`${BASE_URL1}/verify/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error updating verified status:", error);
    throw error;
  }
};

// ✅ Only updates the Published status
export const updatePublishedStatus = async (id) => {
  try {
    const response = await axios.put(`${BASE_URL1}/publish/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error updating published status:", error);
    throw error;
  }
};
