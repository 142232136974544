import axios from "axios";

const BASE_URL = "https://server.ayurdrishti.com";

export const fetchAllLogin = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getalllogin`);
    return response.data;
  } catch (error) {
    console.error("Error fetching login details:", error);
    throw error;
  }
};

export const getLoginById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/getlogin/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching login details:", error);
    throw error;
  }
};

export const getSimpleLoginById = async (id) => {
  try {
    if (!id) {
      throw new Error("ID is required to fetch login details");
    }

    const response = await axios.get(`https://server.ayurdrishti.com/getSimpleLogin/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching login details:", error);
    throw error;
  }
};

export const updateSimpleLogin = async (simpleLoginData) => {
  try {
    const response = await axios.put(`${BASE_URL}/updatelogin`, simpleLoginData);
    return response.data;
  } catch (error) {
    console.error("Error updating login details:", error);
    throw error;
  }
};
