import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Icon from "@mui/material/Icon";
import MDBox from "@mui/material/Box";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import logoCT from "assets/images/WhatsApp Image 2024-10-10 at 10.53.39 AM.png";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const date = new Date().getFullYear();
const content = {
  brand: {
    image: logoCT,
    route: "/",
    style: {
      width: { xs: "80px", sm: "100px" },
      height: { xs: "70px", sm: "90px" },
      marginRight: { xs: "0px", sm: "8px" },
      mb: { xs: 2, sm: 0 },
    },
  },
  socials: [
    {
      icon: <FacebookIcon sx={{ color: "#3b5998" }} />,
      link: "/",
    },
    {
      icon: <TwitterIcon sx={{ color: "#1DA1F2" }} />,
      link: "/",
    },
    {
      icon: <GitHubIcon sx={{ color: "#333" }} />,
      link: "/",
    },
    {
      icon: <YouTubeIcon sx={{ color: "#FF0000" }} />,
      link: "/",
    },
  ],
  menus: [
    {
      name: (
        <MKTypography
          variant="h5"
          sx={{ color: "#FFDD44", fontWeight: "bold", textAlign: { xs: "center", sm: "left" } }}
        >
          Office Address
        </MKTypography>
      ),
      items: [
        {
          name: (
            <MKTypography
              variant="body2"
              sx={{ color: "#D3D3D3", textAlign: { xs: "center", sm: "left" } }}
            >
              Maitri Avenue B, Mahalaxmi Nagar, Hirawadi Road, Panchvati, Nashik, Maharashtra, India
              422003
            </MKTypography>
          ),
        },
      ],
    },
    {
      name: (
        <MKTypography
          variant="h5"
          sx={{ color: "#FFDD44", fontWeight: "bold", textAlign: { xs: "center", sm: "left" } }}
        >
          Contact Us
        </MKTypography>
      ),
      items: [
        {
          name: (
            <MKTypography
              variant="body2"
              sx={{ color: "#D3D3D3", textAlign: { xs: "center", sm: "left" } }}
            >
              Email: ayurdrishti@gmail.com Contact: 8087370701
            </MKTypography>
          ),
        },
      ],
    },
    {
      name: (
        <MKTypography
          variant="h5"
          sx={{ color: "#FFDD44", fontWeight: "bold", textAlign: { xs: "center", sm: "left" } }}
        >
          Useful Links
        </MKTypography>
      ),
      items: [
        {
          name: (
            <MKTypography
              variant="body2"
              sx={{ color: "#D3D3D3", textAlign: { xs: "center", sm: "left" } }}
            >
              About Us
            </MKTypography>
          ),
          href: "/about",
        },
        {
          name: (
            <MKTypography
              variant="body2"
              sx={{ color: "#D3D3D3", textAlign: { xs: "center", sm: "left" } }}
            >
              Articles
            </MKTypography>
          ),
          href: "/articles",
        },
        {
          name: (
            <MKTypography
              variant="body2"
              sx={{ color: "#D3D3D3", textAlign: { xs: "center", sm: "left" } }}
            >
              Editorial Team
            </MKTypography>
          ),
          href: "/editorteam",
        },
        {
          name: (
            <MKTypography
              variant="body2"
              sx={{ color: "#D3D3D3", textAlign: { xs: "center", sm: "left" } }}
            >
              Contact Us
            </MKTypography>
          ),
          href: "/contact",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography
      variant="button"
      fontWeight="regular"
      sx={{
        color: "#D3D3D3",
        textAlign: { xs: "center", sm: "left" },
        display: { xs: "block", sm: "inline" },
      }}
    >
      <MKTypography variant="button" fontWeight="medium" sx={{ color: "#FFDD44" }}>
        &copy; {date}
      </MKTypography>
      , AyurDrishti. All rights reserved. Design & Developed by
      <Icon sx={{ color: "#FFDD44" }} fontSize="inherit">
        favorite
      </Icon>
      <MKTypography variant="button" fontWeight="medium" sx={{ color: "#D3D3D3" }}>
        &nbsp;Brightsea Technology&nbsp;
      </MKTypography>
      .
    </MKTypography>
  ),
};

function DefaultFooter() {
  const { brand, socials, menus, copyright } = content;
  return (
    <MKBox component="footer" sx={{ backgroundColor: "#000d1a", padding: "20px 0" }}>
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={3} sx={{ textAlign: "center", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt="Brand Logo" sx={brand.style} />
              </Link>
            </MKBox>
            <MKBox display="flex" justifyContent="center" mt={2}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  sx={{ color: "white", opacity: 0.8, mx: 1 }}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={12} sm={6} md={2} sx={{ textAlign: "center", mb: 3 }}>
              <MKTypography variant="button" fontWeight="bold" sx={{ color: "#FFDD44" }} mb={1}>
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component={Link}
                        to={href}
                        variant="body2"
                        sx={{ color: "#D3D3D3", textDecoration: "none" }}
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography variant="body2" sx={{ color: "#D3D3D3" }}>
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DefaultFooter;
