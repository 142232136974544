import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { createUser } from "layouts/editorslist/data/fetchUsers";
import { updateUser } from "./fetchUsers";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const UserForm = ({ user, onClose, onUserCreatedOrUpdated }) => {
  const [formData, setFormData] = useState({
    loginId: "",
    name: "",
    userName: "",
    email: "",
    contact: "",
    address: "",
    userPassword: "",
    roles: "USER",
    country: "",
    status: "Active",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (user) {
      setFormData({
        loginId: user.action ? user.action.loginId : user.loginId || "",
        name: user.name,
        userName: user.action ? user.action.userName : user.userName || "",
        email: user.email,
        contact: user.contact,
        address: user.address,
        userPassword: user.action ? user.action.userPassword : user.userPassword || "",
        roles: user.roles,
        country: user.country,
        status: user.status,
      });
    }
  }, [user]);

  const validateForm = () => {
    const errors = {};

    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.contact) errors.contact = "Contact is required";
    else if (!/^\d{10}$/.test(formData.contact)) {
      errors.contact = "Contact must be exactly 10 digits";
    }
    if (!formData.userName) errors.userName = "User Name is required";
    if (!formData.userPassword) errors.userPassword = "User Password is required";
    if (!formData.address) errors.address = "Address is required";
    if (!formData.country) errors.country = "Country is required";

    return errors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const userData = {
        loginId: formData.loginId,
        name: formData.name,
        email: formData.email,
        contact: formData.contact,
        userName: formData.userName,
        userPassword: formData.userPassword,
        address: formData.address,
        roles: formData.roles || "USER",
        country: formData.country,
        status: formData.status || "Active",
      };

      if (user) {
        await updateUser(formData.loginId, userData);
      } else {
        await createUser(userData);
      }

      onUserCreatedOrUpdated();
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        zIndex: 1000,
        width: "90%",
        maxWidth: "400px",
      }}
    >
      <h5>{user ? "Edit User" : "Add User"}</h5>
      <TextField
        label="Name"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        label="Email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        label="Contact"
        value={formData.contact}
        onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.contact}
        helperText={errors.contact}
      />
      <TextField
        label="Address"
        value={formData.address}
        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.address}
        helperText={errors.address}
      />
      <TextField
        label="User Name"
        value={formData.userName}
        onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.userName}
        helperText={errors.userName}
      />
      <TextField
        label="User Password"
        type={showPassword ? "text" : "password"}
        value={formData.userPassword}
        onChange={(e) => setFormData({ ...formData, userPassword: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.userPassword}
        helperText={errors.userPassword}
        InputProps={{
          endAdornment: (
            <Button onClick={handleClickShowPassword} style={{ position: "absolute", right: 10 }}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </Button>
          ),
        }}
      />
      <TextField
        label="Country"
        value={formData.country}
        onChange={(e) => setFormData({ ...formData, country: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.country}
        helperText={errors.country}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="info"
          sx={{
            marginRight: 1,
            borderColor: "info.main",
            color: "info.main",
            "&:hover": {
              backgroundColor: "info.light",
              borderColor: "info.dark",
            },
          }}
        >
          {user ? "Update" : "Submit"}
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: "error.main",
            borderColor: "error.main",
            "&:hover": {
              backgroundColor: "error.light",
              borderColor: "error.dark",
            },
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

UserForm.propTypes = {
  user: PropTypes.shape({
    loginId: PropTypes.number,
    name: PropTypes.string,
    userName: PropTypes.string,
    userPassword: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.string,
    address: PropTypes.string,
    roles: PropTypes.string,
    country: PropTypes.string,
    status: PropTypes.string,
    action: PropTypes.shape({
      loginId: PropTypes.number,
      userName: PropTypes.string,
      userPassword: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
      country: PropTypes.string,
      roles: PropTypes.string,
      status: PropTypes.string,
      contact: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func.isRequired,
  onUserCreatedOrUpdated: PropTypes.func.isRequired,
};

export default UserForm;
