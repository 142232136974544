import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "examples/Tables/DataTable";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";
import axios from "axios";

const VerifiedButton = ({ row }) => {
  const status = row.original.status;

  const buttonStyles = {
    borderRadius: "20px",
    margin: "0 5px",
    textTransform: "none",
    borderColor: status === "VERIFIED" || status === "ASSIGNED" ? "green" : "red",
    color: status === "VERIFIED" || status === "ASSIGNED" ? "green" : "red",
  };

  const buttonText =
    status === "VERIFIED" ? "Verified" : status === "ASSIGNED" ? "Assigned" : "Need Correction";

  return (
    <Button variant="outlined" style={buttonStyles}>
      {buttonText}
    </Button>
  );
};

const ViewFileButton = ({ row }) => (
  <Button
    variant="outlined"
    onClick={() => handleOpenFile(row.original.file, row.original.fileType)}
    style={{
      borderRadius: "20px",
      margin: "0 5px",
      textTransform: "none",
      color: "green",
      borderColor: "green",
    }}
  >
    Download File
  </Button>
);

const handleOpenFile = (fileData, fileType) => {
  try {
    if (!fileData) {
      console.error("File not found");
      return;
    }

    const base64Prefix = "data:" + fileType + ";base64,";

    const base64String = fileData.startsWith(base64Prefix) ? fileData.split(",")[1] : fileData;

    const byteCharacters = atob(base64String);
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: fileType });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "downloaded_file" + (fileType === "application/msword" ? ".doc" : ".docx");
    link.click();
  } catch (error) {
    console.error("Error handling file:", error);
  }
};

const AssignArticleDataTable = ({ onEdit }) => {
  const [assignArticle, setAssignVerifiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVerifier, setSelectedVerifier] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);

  const fetchAssignVerifiers = async () => {
    try {
      const userId = sessionStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in sessionStorage");
      }

      const response = await axios.get(
        `https://server.ayurdrishti.com/api/assign-verifiers/getAssignVerifierById/${userId}`
      );

      const formattedData = response.data.map((verifier, index) => {
        const editorName =
          verifier.login?.roles === "EDITOR" ? verifier.login.name : "Not Assigned";

        return {
          srNo: index + 1,
          articleTitle: verifier.article?.title || "Untitled",
          status: verifier.status || "NEED_CORRECTION",
          comments: verifier.comment || "No Comment",
          editorName: editorName,
          file: verifier.article?.file || null,
          action: (
            <div>
              <IconButton color="primary" onClick={() => handleEdit(verifier)}>
                <EditIcon />
              </IconButton>
            </div>
          ),
        };
      });

      setAssignVerifiers(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error.message, error.response || error);
      setError("No Article Data Found.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `https://server.ayurdrishti.com/api/assign-verifiers/updateAssignVerifier/${selectedVerifier.id}`,
        {
          status: status,
          comment: comments,
          article: { id: selectedVerifier.article.id },
          login: { id: selectedVerifier.login.id },
        }
      );

      setOpenForm(false);
      fetchAssignVerifiers();
    } catch (error) {
      console.error("Error updating verifier:", error);
    }
  };

  ViewFileButton.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        file: PropTypes.string,
        fileType: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  const handleEdit = async (verifier) => {
    try {
      const response = await axios.get(
        `https://server.ayurdrishti.com/api/assign-verifiers/getAssignVerifierDetailsById/${verifier.id}`
      );
      const data = response.data;
      setSelectedVerifier(data);
      setStatus(data.status);
      setComments(data.comment || "");
      setOpenForm(true);
    } catch (error) {
      console.error("Error fetching verifier details:", error);
    }
  };

  useEffect(() => {
    fetchAssignVerifiers();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <DataTable
        table={{
          columns: [
            { Header: "Sr. No", accessor: "srNo", width: "10%", align: "center" },
            { Header: "Article Name", accessor: "articleTitle", align: "left" },
            {
              Header: "View File",
              accessor: "file",
              align: "center",
              Cell: ViewFileButton,
            },
            { Header: "Status", accessor: "status", align: "center", Cell: VerifiedButton },
            { Header: "Comments", accessor: "comments", align: "center" },
            { Header: "Editor", accessor: "editorName", align: "center" },
            { Header: "Action", accessor: "action", align: "center" },
          ],
          rows: assignArticle,
        }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
      />

      <Dialog open={openForm} onClose={() => setOpenForm(false)}>
        <DialogTitle>Edit Verifier Status</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={3}>
                <TextField
                  fullWidth
                  label="Select Status"
                  variant="outlined"
                  select
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    if (e.target.value !== "NEED_CORRECTION") {
                      setComments("");
                    }
                  }}
                  sx={{
                    minWidth: 400,
                    maxWidth: 600,
                    "& .MuiInputBase-root": {
                      height: 40,
                    },
                  }}
                >
                  <MenuItem value="ASSIGNED">ASSIGNED</MenuItem>
                  <MenuItem value="VERIFIED">VERIFIED</MenuItem>
                  <MenuItem value="NEED_CORRECTION">NEED_CORRECTION</MenuItem>
                </TextField>
              </Grid>

              {status === "NEED_CORRECTION" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Comment"
                    variant="outlined"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    multiline
                    rows={4}
                  />
                </Grid>
              )}

              <Grid item xs={12} textAlign="center">
                <Button
                  type="submit"
                  variant="outlined"
                  color="info"
                  sx={{
                    marginRight: 1,
                    borderColor: "info.main",
                    color: "info.main",
                    "&:hover": { backgroundColor: "info.light", borderColor: "info.dark" },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenForm(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AssignArticleDataTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

VerifiedButton.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

ViewFileButton.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      file: PropTypes.string,
      fileType: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default AssignArticleDataTable;
