import React from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PropTypes from "prop-types";

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const isDarkColor = (color) => {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance < 128;
};

const ArticleCard = ({
  title,
  journal,
  pdfFile,
  articleId,
  handleDownloadFile,
  handleOpenFile,
  articleDetails,
}) => {
  const titleBackgroundColor = "#abbab2";
  const titleTextColor = "#333333";
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "0px",
        marginBottom: "10px",
        borderRadius: "10px",
        boxShadow: "0 20px 15px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease",
        "&:hover": { transform: "translateY(-5px)" },
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "8px" }}
      >
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#f5f5f5",
            padding: "6px 12px",
            borderRadius: "8px",
            marginRight: "16px",
            minWidth: "80px",
          }}
        >
          <Typography variant="h6" sx={{ color: "#888", fontSize: "14px" }}>
            {journal}
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            onClick={() => handleDownloadFile(pdfFile, articleId)}
            sx={{
              cursor: "pointer",
              fontWeight: "bold",
              backgroundColor: titleBackgroundColor,
              color: titleTextColor,
              textTransform: "none",
              width: "90%",
              padding: "6px 16px", // Adjust padding to reduce space
              borderRadius: "8px",
              textDecoration: "underline",
              fontSize: "14px", // Adjust font size to reduce space
              "&:hover": {
                color: "#1976d2",
              },
            }}
          >
            {title}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdfIcon sx={{ color: "white" }} />}
            onClick={() => handleOpenFile(pdfFile)}
            sx={{
              color: "white !important",
              fontWeight: "bold",
              textTransform: "none",
              backgroundColor: "#1976d2",
              "&:hover": { backgroundColor: "#0056b3" },
              minWidth: "auto", // Remove extra width space
            }}
          >
            PDF
          </Button>
        </Box>
      </CardContent>

      <CardContent sx={{ mt: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "8px",
          }}
        >
          <Typography variant="body2">
            <strong>Type:</strong> {articleDetails?.articleComponent || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Author:</strong>{" "}
            {articleDetails?.authors?.map((author) => author.name).join(", ") || "Unknown"}
          </Typography>
          <Typography variant="body2">
            <strong>Received On:</strong> {articleDetails?.dateOfSubmission || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Accepted On:</strong> {articleDetails?.expectedPublishDate || "N/A"}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  journal: PropTypes.string.isRequired,
  pdfFile: PropTypes.string.isRequired,
  articleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
  handleOpenFile: PropTypes.func.isRequired,
  articleDetails: PropTypes.shape({
    articleComponent: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    dateOfSubmission: PropTypes.string,
    expectedPublishDate: PropTypes.string,
  }).isRequired,
};

export default ArticleCard;
