import React, { useState, useEffect } from "react";
import { AppBar, Box, Tabs, Tab, Card, TablePagination } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import breakpoints from "assets/theme/base/breakpoints";
import AssignVerifierForm from "./AddAssignVerifierForm";
import AssignVerifierDataTable from "layouts/assignverifierform/components/Header/AssignVerifierDataTable";

function Header() {
  const [tabValue, setTabValue] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [assignVerifiers, setAssignVerifiers] = useState([]); // State for storing the assign verifiers

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const handleTabsOrientation = () => {
      setTabsOrientation(window.innerWidth < breakpoints.values.sm ? "vertical" : "horizontal");
    };

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAssignSuccess = (newAssignVerifier) => {
    setAssignVerifiers((prevVerifiers) => [...prevVerifiers, newAssignVerifier]);
  };

  const handleEdit = (verifier) => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Get paginated data
  const paginatedVerifiers = assignVerifiers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <MDBox position="relative" mb={5}>
      <Card
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {/* AppBar with Tabs */}
        <Box sx={{ mt: 2 }}>
          <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
            <Tabs
              value={tabValue}
              onChange={handleSetTabValue}
              centered
              orientation={tabsOrientation}
              sx={{
                "& .MuiTabs-flexContainer": {
                  borderBottom: "1px solid #e0e0e0",
                },
              }}
            >
              <Tab
                label="Add"
                sx={{
                  color: tabValue === 0 ? "red" : "#000",
                  fontWeight: tabValue === 0 ? "bold" : "normal",
                  borderBottom: tabValue === 0 ? "2px solid red" : "none",
                }}
              />
              <Tab
                label="View"
                sx={{
                  color: tabValue === 1 ? "green" : "#000",
                  fontWeight: tabValue === 1 ? "bold" : "normal",
                  borderBottom: tabValue === 1 ? "2px solid red" : "none",
                }}
              />
            </Tabs>
          </AppBar>

          {tabValue === 0 && <AssignVerifierForm onAssignSuccess={handleAssignSuccess} />}

          {tabValue === 1 && (
            <Card
              sx={{
                mt: 4,
                p: 0,
                maxWidth: "1200px",
                mx: "auto",
                width: "100%",
                boxShadow: (theme) => theme.shadows[4],
              }}
            >
              <MDBox
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  mx: "auto",
                  mt: -2,
                  backgroundColor: (theme) => theme.palette.info.main,
                  borderRadius: "8px",
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: (theme) => theme.shadows[4],
                }}
              >
                <MDTypography variant="h6" color="white">
                  All Assigned Verifiers
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <AssignVerifierDataTable assignVerifiers={paginatedVerifiers} onEdit={handleEdit} />
              </MDBox>

              {/* Pagination */}
              <TablePagination
                component="div"
                count={assignVerifiers.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </Box>
      </Card>
    </MDBox>
  );
}

export default Header;
