import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { createUser, updateUser } from "layouts/editorslist/data/fetchUsers";

const EditorForm = ({ user, onClose, onUserCreatedOrUpdated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    userPassword: "",
    roles: "EDITOR",
    country: "",
    status: "Active",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        email: user.email,
        contact: user.contact,
        address: user.address,
        userName: user.userName,
        userPassword: "",
        roles: "EDITOR",
        country: user.country,
        status: user.status,
      });
    }
  }, [user]);

  const validateForm = () => {
    const errors = {};

    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.contact) errors.contact = "Contact is required";
    else if (!/^\d{10}$/.test(formData.contact)) {
      errors.contact = "Contact must be exactly 10 digits";
    }
    if (!formData.address) errors.address = "Address is required";
    if (!formData.country) errors.country = "Country is required";
    if (!formData.userName) errors.userName = "User Name is required";
    if (!formData.userPassword) errors.userPassword = "userPassword is required";
    else if (formData.userPassword.length < 8) {
      errors.userPassword = "userPassword must be at least 8 characters long";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const userData = {
        name: formData.name,
        email: formData.email,
        contact: formData.contact,
        address: formData.address,
        userName: formData.userName,
        userPassword: formData.userPassword,
        roles: formData.roles || "EDITOR",
        country: formData.country,
        status: formData.status || "Active",
      };

      if (user) {
        await updateUser(user.id, formData);
      } else {
        await createUser(formData);
      }
      onUserCreatedOrUpdated();
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        zIndex: 1000,
        width: "90%",
        maxWidth: "400px",
      }}
    >
      <h5>{user ? "Edit Peer Team" : "Add Peer Team"}</h5>
      <TextField
        label="Name"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        label="Email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        label="Contact"
        value={formData.contact}
        onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.contact}
        helperText={errors.contact}
      />
      <TextField
        label="Address"
        value={formData.address}
        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.address}
        helperText={errors.address}
      />
      <TextField
        label="User Name"
        value={formData.userName}
        onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.userName}
        helperText={errors.userName}
      />
      <TextField
        label="User Password"
        type="userPassword"
        value={formData.userPassword}
        onChange={(e) => setFormData({ ...formData, userPassword: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.userPassword}
        helperText={errors.userPassword}
      />
      <TextField
        label="Country"
        value={formData.country}
        onChange={(e) => setFormData({ ...formData, country: e.target.value })}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.country}
        helperText={errors.country}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="info"
          sx={{
            marginRight: 1,
            borderColor: "info.main",
            color: "info.main",
            "&:hover": {
              backgroundColor: "info.light",
              borderColor: "info.dark",
            },
          }}
        >
          {user ? "Update" : "Submit"}
        </Button>

        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: "error.main",
            borderColor: "error.main",
            "&:hover": {
              backgroundColor: "error.light",
              borderColor: "error.dark",
            },
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

EditorForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.string,
    address: PropTypes.string,
    userName: PropTypes.string,
    userPassword: PropTypes.string,
    roles: PropTypes.string,
    country: PropTypes.string,
    status: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onUserCreatedOrUpdated: PropTypes.func.isRequired,
};

export default EditorForm;
